import axios from '@/config/httpConfig'

//上传图片文件
export function uploadImg(data) {
  return axios.post('/file/uploadImg', data)
}

//上传文件
export function upload(data, config) {
  return axios.post('/file/upload', data, config)
}

//获取文件大小限制
export function queryLimit(data) {
  return axios.post('/file/queryLimit', data)
}