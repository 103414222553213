<!--  -->
<template>
<div style="width:100%">
    <el-popover
        placement="bottom-start"
        v-model="visible"
        trigger="click"
        :width="worketwidth"
    >
    <div class="workethour" ref="workedd">
        <div>
            <el-radio v-model="radio" label="1">{{$t('c204')}}</el-radio>
            <!-- <el-radio v-model="radio" label="2">灵活</el-radio> -->
        </div>
        <div v-if="radio=='1'" class="radio1">
            <div class="top">
                <p class="leftp">{{$t('label_person_in_charge')}}</p>
                <el-select v-model="value" size='mini'>
                    <el-option
                    popper-class="userworkingoption"
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="bottom">
                <div v-for="(item,index) in workehoursuser" :key="index" class="userhour">
                    <p class="leftp">{{item.label}}</p>
                    <p>
                        <span></span>
                        <input type="text" v-model="userworkhour[item.value]" @keyup="proving(item.value)" @blur="workingblur(item.value)"> h/天
                        <!-- <el-input v-model="userworkhours[item]"></el-input>  -->
                    </p>
                </div>
            </div>  
        </div>
        <div class="footer">
                <el-button size="mini" @click.native="chancl">{{$t('label.emailtocloudcc.button.back')}}</el-button>
                <el-button type="primary" size="mini"  @click="save">{{$t('label.tabpage.ok')}}</el-button>
            </div>
    </div>
    <div slot="reference" class="downIcon">
    </div>
    </el-popover>
  </div>
</template>

<script>
import * as callCenterApi from "../api.js"; // 计算工时
import * as taskhour from "@/components/cloudcc-gantt/components/downList/api.js";
export default {
    props:{
        workehoursuser:{
            type:Array,
            default:[]
        },
        workehoursstate:{
            type:String,
            default:''
        },
        workehoursend:{
            type:String,
            default:''
        },
        idd:{
            type:String,
            default:''
        },
        worketwidth:{
            type:String,
            default:''
        }
    },
  data () {
    return {
        radio: '1',
        options: [{
          value: '1',
          label: this.$i18n.t('label.projectManagement.working.hours.per.day')
        },
        //  {
        //   value: '2',
        //   label: '总工作小时数'
        // }
        ],
        value: '1',
        userworkhour:{},
        userworkhours:{},
        visible:false,
    }
  },
  watch:{
    workehoursstate(){
       this.save(); 
    },
    workehoursend(){
        this.save();
    },
    workehoursuser(){
        this.workehoursuser=this.workehoursuser
        if(this.idd){
            this.gethuors()
        }else{
            this.save();
        }
        
    },
    visible(){
        if(this.visible==true){
            this.$emit('visiblechange')
        }
    }
  },
  methods:{
      gethuors(){
        let  params={
            taskId:this.idd
        }
        taskhour.getTaskHour(params).then((res)=>{
            if(res.result){
                this.userworkhour={}
                res.data.forEach(el => {
                    this.$set(this.userworkhour,el.principal,el.dailyWorkingHours)
                    // this.workehoursuser.push({label:el.principalccname,value:el.principal})
                    
                });
            //     let map = new Map();
            //     for (let item of this.workehoursuser) {
            //         if (!map.has(item.value)) {
            //         map.set(item.value, item);
            //         };
            //     };
            //    this.$nextTick(()=>{
            //         this.workehoursuser = [...map.values()];
            //    })
            this.save()
            this.userworkhours=JSON.parse(JSON.stringify(this.userworkhour))
          }  
        })
      },
      //取消
      chancl(){
        // this.$emit("chancl")
        this.visible=false
      },
      //确定
      save(){
        let arr=[]
        this.workehoursuser.forEach((res)=>{
            arr.push(res.value)
        })
        let obj={}
        arr.forEach((res)=>{
            if(this.userworkhour[res]){
                obj[res]=this.userworkhour[res]
            }else{
                obj[res]='8' 
                this.userworkhour[res]='8'
            }
            
        })
        this.userworkhours=obj
        let params={
            startDate:this.workehoursstate,
            endDate:this.workehoursend,
            data:JSON.stringify(obj),
        }
        callCenterApi.getWorkHour(params).then((res)=>{
            if(res.result){
              this.visible=false
              if(res.data.continueDate<1){
                this.$message.error(this.$i18n.t('label.appointment.wizard.notif1'))
                this.$emit("workethoursave",JSON.stringify(this.userworkhours),'8','0')
              }else{
                this.$emit("workethoursave",JSON.stringify(this.userworkhours),res.data.workingHours,res.data.continueDate.toString())
                this.$cookies.set('workinghuorss',this.userworkhours, { sameSite: 'Strict' })
              }
            }
        })
      },
      proving(va){
        const reg = /^[+]?([0-9]+(.[0-9]{0,2})?)$/;
        if (!reg.test(this.userworkhour[va])) {
            this.$message.warning(
            this.$i18n.t(
                "message.validation.isnotdigits"
            )
            );
            this.userworkhour[va]=''
        }else{
             if(this.userworkhour[va]>=0 && this.userworkhour[va]<=24){
                this.userworkhours[va]=this.userworkhour[va]
            }else{
                 this.$message.warning(
            this.$i18n.t(
                "message.validation.isnotdigits"
            )
            );
            this.userworkhour[va]=''
            }
        }
      },
      workingblur(va){
      if(this.userworkhour[va]){
        let xiaoshu=this.userworkhour[va].split('.')[1]
        if(!xiaoshu){
          this.userworkhours[va] = this.userworkhour[va].split('.')[0]
        }
      }
    },
    // days(){
    //       
    //     let at = this.$moment(this.workehoursstate).format('YYYY-MM-DD')
    //     let to = this.$moment(this.workehoursend).format('YYYY-MM-DD')
    //     let statji=this.$moment(at).format('d');
    //     
    //     
    // }
  },
  mounted(){
    // if(this.$cookies.get('workinghuorss')){
       
    //     this.userworkhour={}
    //     this.userworkhours={}
    //     
    //     // this.workehoursuser=JSON.parse(this.$cookies.get('workehoursusers')) ;
    //     // this.userworkhour=Object.assign({}, this.$cookies.get('workinghuorss'))
    //     // let obj=Object.keys()  ;
    //     // 
    //     // this.userworkhours=this.$cookies.get('workinghuorss')
    //     // this.$cookies.set('workinghuorss',"")      
    //     // this.$cookies.set('workehoursusers',[])
    // }else
    //  if(this.idd){
    //     this.gethuors()   
    // }
  }
}
</script>

<style lang='scss' scoped >
.downIcon{
    display: flex;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    word-break: break-all;
    margin-top: 5px;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    position: absolute;
    top:0px;
}
.workethour{
    margin:10px 5%;
}
.radio1{
    width: 100%;
    .top{
       display: flex; 
       align-items: center;
       justify-content: space-between;
       height: 50px;
       border-bottom: 1px solid #ccc;
    }
    .leftp{
        width: 50%;
    }
    ::v-deep .el-input__inner{
        border:none;
        text-align: right;
    }
    ::v-deep .el-popper[x-placement^=bottom] .popper__arrow{
      
        display: none !important;
        
       
    }
    .userhour{
        display: flex; 
       align-items: center;
       justify-content: space-between;
       height: 50px;
       input{
           width: 40px;
           outline: none;
       }
    }
}
.bottom{
    max-height: 150px;
    overflow: auto;
    margin-top:5px;
    padding-right:5px;
}
.footer{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: right;
}
</style>