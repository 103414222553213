export default {
  data() {
    return {
      // 地址类型字段信息
      clearItemArr: {},

      // 客户地址数组
      addressArr: [],
      // 是否展示谷歌/高德地图弹窗
      bigMapDialog: false,

      // 是否是高德地图
      isMap: true,
      // 详细地址 前半部分
      detailAdd:"",
      // 详细地址 后半部分  门牌号
      houseNum:"",
      isHouseNumDisAble: false
    };
  },
  methods: {
   
    // 下拉选项框出现时候触发
    visibleChange(flag) {
      // 如果显示  获取所有下拉选项框 并改变他们的最大宽度
      if (flag) {
        let elSelectDropdown =
          document.getElementsByClassName("el-select-dropdown");
        for (let i = 0; i < elSelectDropdown.length; i++) {
          let el = elSelectDropdown[i];
          el.style.maxWidth = el.style.minWidth;
        }
      }
      //为解决地址字段国家下拉框显示不出来问题wxr-24017
      this.$forceUpdate();
    },
 
  },
};
