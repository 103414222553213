<template lang="html">
  <div class="editor">
     <el-dialog
       :title="$t('label.knowledgebase.article.link')"
       :visible.sync="dialogVisible"
       width="40%"
       :modal-append-to-body='false'
       :append-to-body="true"
       :before-close="handleClose">
         <el-form :model="ruleForm" ref="ruleForm" label-width="90px" class="demo-ruleForm">
          <el-form-item :label="$t('label.language')" prop="languagevalue" style="margin-top:20px;margin-bottom:20px">
            <el-col :span="12">
            <el-select v-model="ruleForm.languagevalue" 
              :placeholder="$t('label.campaign.channel.input.to.chooselanguage')" @change="releasechange">
              <el-option v-for="item in languageoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
           </el-select>
           </el-col>
            <el-col :span="12">
             <el-form-item :label="$t('label.knowledgebase.release.status')" prop="releasestatus">
            <el-select v-model="ruleForm.releasestatus" 
              :placeholder="$t('label.knowledgebase.select.publish.status')" @change="releasechange">
              <el-option v-for="item in releaseoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
           </el-select>
           </el-form-item>
           </el-col>
         </el-form-item>


         <el-form-item :label="$t('label.knowledgebase.article.link')" prop="articlevalue" 
            style="amrgin-top:20px;margin-bottom:20px">
         <el-popover
          placement="bottom"
          width="400"
          popper-class="pop"
          v-model="visible"
          trigger="click">
          <ul style="margin: 0 -12px;">
            <li
            style="cursor: pointer;padding-left:20px;margin-top:5px"
            @click="choseRecord(index, item)"
            v-for="(item, index) in filterList"
            :key="index"
            v-html="
              highLight(
                item.name,
                ruleForm.articlename
              )
            "
          >
            <span>{{ item.name }}</span>
          </li>
          <li style="cursor: pointer;padding:10px" @click="add">
            <span class="el-icon-plus" style="margin-right:5px"></span>
            {{$t('label.create.object')}}
          </li>
          </ul>
          <span slot="reference"> 
           <el-input
            :placeholder="$t('label.tabpage.entercontent')"
             suffix-icon="el-icon-search"
             @input="findConditionKnowledgeArticle"
             @focus="findConditionKnowledgeArticle"
             v-model="ruleForm.articlename">
           </el-input></span>
         </el-popover>
         </el-form-item>

          <el-form-item :label="$t('label.target')" prop="targetvalue">
            <el-col :span="12">
            <el-select v-model="ruleForm.targetvalue" :placeholder="$t('label.knowledgebase.select.target')">
              <el-option v-for="item in targetoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
           </el-select>
           </el-col>
           <el-col :span="12">
             <el-form-item :label="`${'lighting'}${$t('label.target')}`" prop="lightingvalue">
            <el-select v-model="ruleForm.lightingvalue" :placeholder="$t('label.knowledgebase.select.lighting.target')">
              <el-option v-for="item in lightingoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
           </el-select>
         </el-form-item>
         </el-col>
         </el-form-item>

         <el-form-item :label="$t('label.knowledgebase.target.framework.name')" prop="kuanginput" 
            v-if="ruleForm.targetvalue==`${'<'}${$t('label.knowledgebase.framework')}${'>'}`">
           <el-input v-model="ruleForm.kuanginput" :placeholder="$t('label.tabpage.entercontent')"></el-input>
         </el-form-item>

         <div style="text-align:center;margin-top:20px">
    <el-button type="primary" @click="submitForm('ruleForm')" style="text-align:center">{{$t('label.tabpage.ok')}}</el-button>
    <el-button @click="cancel('ruleForm')">{{$t('label.chatter.cancel')}}</el-button></div>
</el-form>
</el-dialog>
  </div>
</template>

<script>
import * as knowledgearticlesApi from "./api.js";
export default {
  data() {
    return {
      dialogVisible: false,
      visible: false,
      
      languageoptions: [],
      
      releaseoptions: [
        {
          value: this.$i18n.t("label.knowledgebase.published"),
          label: this.$i18n.t("label.knowledgebase.published"),
        },
        {
          value: this.$i18n.t("label.knowledgebase.draft"),
          label: this.$i18n.t("label.knowledgebase.draft"),
        },
      ],
      lightingoptions: [
        {
          value: `${"<"}${this.$i18n.t("label.ems.nomsg")}${">"}`,
          label: `${"<"}${this.$i18n.t("label.ems.nomsg")}${">"}`,
        },
        {
          value: this.$i18n.t(
            "label.knowledgebase.application.default.settings"
          ),
          label: this.$i18n.t(
            "label.knowledgebase.application.default.settings"
          ),
        },
        {
          value: this.$i18n.t("label.knowledgebase.same.taborworkspace"),
          label: this.$i18n.t("label.knowledgebase.same.taborworkspace"),
        },
        {
          value: this.$i18n.t("label.knowledgebase.createnewbrowser"),
          label: this.$i18n.t("label.knowledgebase.createnewbrowser"),
        },
        {
          value: this.$i18n.t("label.knowledgebase.new.workspace"),
          label: this.$i18n.t("label.knowledgebase.new.workspace"),
        },
        {
          value: this.$i18n.t("label.knowledgebase.create.new.subtab"),
          label: this.$i18n.t("label.knowledgebase.create.new.subtab"),
        },
      ],
      
      targetoptions: [
        {
          value: `${"<"}${this.$i18n.t("label.ems.nomsg")}${">"}`,
          label: `${"<"}${this.$i18n.t("label.ems.nomsg")}${">"}`,
        },
        {
          value: `${"<"}${this.$i18n.t("label.knowledgebase.framework")}${">"}`,
          label: `${"<"}${this.$i18n.t("label.knowledgebase.framework")}${">"}`,
        },
        {
          value: `${this.$i18n.t("label.newWindow")}${"(_blank)"}`,
          label: `${this.$i18n.t("label.newWindow")}${"(_blank)"}`,
        },
        {
          value: `${this.$i18n.t("label.knowledgebase.top.window")}${"(_top)"}`,
          label: `${this.$i18n.t("label.knowledgebase.top.window")}${"(_top)"}`,
        },
        {
          value: `${this.$i18n.t(
            "label.knowledgebase.self.window"
          )}${"(_self)"}`,
          label: `${this.$i18n.t(
            "label.knowledgebase.self.window"
          )}${"(_self)"}`,
        },
        {
          value: `${this.$i18n.t(
            "label.knowledgebase.parent.window"
          )}${"(_parent)"}`,
          label: `${this.$i18n.t(
            "label.knowledgebase.parent.window"
          )}${"(_parent)"}`,
        },
      ],
      ruleForm: {
        languagevalue: "",
        releasestatus: this.$i18n.t("label.knowledgebase.published"),
        articlevalue: "",
        articlename: "",
        targetvalue: `${"<"}${this.$i18n.t("label.ems.nomsg")}${">"}`,
        lightingvalue: `${"<"}${this.$i18n.t("label.ems.nomsg")}${">"}`,
        kuanginput: "",
      },
      filterList: [],
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  created() {
    this.listenStorage();
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.listenStorageFn);
  },
  methods: {
    // 监听查找字段新增事件
    listenStorage() {
      window.addEventListener("storage", this.listenStorageFn);
    },
    listenStorageFn(event) {
      // 监听addRelevantObjectType变化
      if (event.key === "relevantObjectDataId") {
        (this.ruleForm.articlename = localStorage.getItem(
          "relevantObjectDataName"
        )),
          (this.ruleForm.articlevalue = localStorage.getItem(
            "relevantObjectDataId"
          )),
          (this.visible = false);
      }
    },
    add() {
      window.open(
        `#/add-relevant-obj/k02/CloudccKArticle/0?addType=option&prop=name`
      );
    },
    choseRecord(index, item) {
      this.ruleForm.articlevalue = item.id;
      this.ruleForm.articlename = item.name;
      this.visible = false;
    },
    releasechange() {
      this.findConditionKnowledgeArticle();
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
    cancel(formName) {
      this.ruleForm.articlename = "";
      this.ruleForm.articlevalue = "";
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    //为用户输入的内容设置高亮
    highLight(val, keyword) {
      let Reg = new RegExp(keyword, "g");
      if (val) {
        const res = val.replace(
          Reg,
          `<span style="color: #3aa1ff;">${keyword}</span>`
        );
        return res;
      }
    },
    //知识文章内部链接弹窗
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
          this.articlevaluestr = this.ruleForm.articlevalue;
          if (
            this.ruleForm.lightingvalue ==
              this.$i18n.t("label.knowledgebase.same.taborworkspace") ||
            this.ruleForm.lightingvalue ==
              this.$i18n.t("label.knowledgebase.new.workspace")
          ) {
            let str = `<p><a href='/#/commonObjects/detail/${this.ruleForm.articlevalue}/DETAIL' target="_self">${this.ruleForm.articlename}</a><span style="display:none">${this.ruleForm.targetvalue};${this.ruleForm.lightingvalue}</span><span style="display:none">${this.ruleForm.kuanginput}</span></p>`;
            this.$parent.editor.txt.append(str);
          } else {
            let str = `<p><a href='/#/commonObjects/detail/${this.ruleForm.articlevalue}/DETAIL' target="_blank">${this.ruleForm.articlename}</a><span style="display:none">${this.ruleForm.targetvalue};${this.ruleForm.lightingvalue}</span><span style="display:none">${this.ruleForm.kuanginput}</span></p>`;
            this.$parent.editor.txt.append(str);
          }
          this.ruleForm.articlename = "";
          this.ruleForm.articlevalue = "";
          this.$refs[formName].resetFields();
        } else {
          return false;
        }
      });
    },
    //根据语言和状态获取知识文章
    findConditionKnowledgeArticle() {
      let params = {
        language: this.ruleForm.languagevalue,
        status: this.ruleForm.releasestatus,
        name: this.ruleForm.articlename,
      };
      knowledgearticlesApi.findConditionKnowledgeArticle(params).then((res) => {
        this.filterList = res.data.splice(0, 5);
      });
    },
  },
};
</script>