<template>
  <div>
    <el-upload
      class="upload-myself"
      :class="{ 'upload-myself-info': isShowOpeTip }"
      ref="upload"
      :action="action"
      :http-request="uploadFile"
      :before-upload="beforeUpload"
      :multiple="multiple"
      :limit="limit"
      :list-type="listType"
      :auto-upload="true"
      :disabled="disabled"
      :file-list="formUploadFileList"
      :show-file-list="showFileList"
      :on-preview="preview"
      :on-success="success"
      :on-exceed="handleExceed"
      :on-error="error"
    >
      <i class="el-icon-plus" v-if="listType === 'picture-card'"> </i>
      <div slot="file" slot-scope="{ file }" v-if="listType === 'picture-card'">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            class="el-upload-list__item-delete"
            @click="handlePictureCardRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <el-button
        slot="trigger"
        :disabled="disabled"
        size="mini"
        type="primary"
        v-if="listType !== 'picture-card'"
      >
        <!-- 点击上传 -->
        {{ $t("label.file.uploadfiles") }}
      </el-button>
      <div class="el-upload__tip" slot="tip" v-if="listType !== 'picture-card'">
        <p>
          <!-- 文件大小不超过{{(Number(size) / 1024).toFixed(2)}}Mb -->
          {{
            $t("vue_label_file_size_limit", {
              size: (Number(size) / 1024).toFixed(2),
            })
          }}
        </p>
        <p :v-show="showMsg" style="color: red">{{ msg }}</p>
      </div>
      <ul
        class="upload-text-info"
        v-if="isShowOpeTip && listType !== 'picture-card'"
      >
        <li>
          <!-- 如果需要替换已上传的文件，请点击【X】按钮后重新上传 -->
          {{ $t("vue_label_commonobject_notice_clickcross") }}
        </li>
        <li>
          <!-- 如需要下载已上传文件，请点击文件名称 -->
          {{ $t("vue_label_commonobject_duplicatewith") }}
        </li>
      </ul>
    </el-upload>

    <!-- 图片预览 -->
    <file-preview
      :show="showPreview"
      :showData="showPreviewData"
      :fromList="true"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>
  </div>
</template>

<script type="text/ecmascript-6">
import { uploadImg, upload } from "./api";
import filePreview from "@/components/FileView/filePreview";

export default {
  components: { filePreview },
  props: {
    prop: {
      type: String,
      default: "file",
    },
    fileName: {
      type: String,
      default: "",
    },
    fileType: {
      type: Array,
    },
    listType: {
      type: String,
      default: "",
    },
    size: {
      // 上传的大小
      type: Number,
      default: 10240,
    },
    showMsg: {
      // 是否显示提示语
      type: Boolean,
      default: false,
    },
    showOperateTip: {
      type: Boolean,
      default: true,
    },
    msg: {
      type: String,
      default: "",
    },
    callback: {
      type: [Function, undefined],
      default: undefined,
    },
    fileApi: {
      type: Object,
    },
    action: {
      // 请求地址
      type: String,
      default: "test",
    },
    multiple: {
      type: Boolean, // 是否可多选
      default: false,
    },
    data: {
      type: Object, // 额外参数
      default: () => {
        return {};
      },
    },
    fileList: {
      type: Array, // 上传的文件列表
      default: () => [],
    },
    disabled: {
      type: Boolean, // 是否禁用
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
    fileAttr: {
      type: String,
    },
    beforeUploadFile: {
      type: Function,
    },
    showFileList: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      formUploadFileList: [], // 上传列表合集
      showPreview: false, // 预览
      showPreviewData: {}, // 预览数据
      previewFileId: "", //预览文件的id
    };
  },
  created() {
    this.getFormUploadFileList();
  },
  methods: {
    /**
     * 图片上传达到上限后隐藏、展示上传按钮入口
     * @param {String} isShow:控制显示隐藏
     */
    isShow(isShow = "") {
      if (this.listType === "picture-card") {
        // isShow:'inline-block' 展示； 'none': 隐藏
        this.$refs.upload.$children[1].$el.style.display = isShow;
      }
    },
    getFormUploadFileList() {
      this.formUploadFileList = [];
      if (this.fileList && this.fileList.length > 0) {
        if (this.fileType.indexOf("png") !== -1) {
          this.formUploadFileList = this.fileList;
        } else {
          this.fileList.forEach((file) => {
            this.formUploadFileList.push({
              name: `${file.name}.${file.type}`,
              status: "ready",
              uid: file.id,
            });
          });
        }
      }
    },
    // 图片放大
    handlePictureCardPreview(file) {
      this.showPreview = true;
      if (this.item.value) {
        this.previewFileId = this.item.value;
      }
      this.showPreviewData = {
        type: "jpg",
        id: this.previewFileId,
        isUseNativePreview: true, //不使用插件进行图片预览
      };
      this.$refs.previewFile.handleBtn(["download"]);
    },
    closePreview() {
      // 列表打开预览
      this.showPreview = false;
      this.previewUrl = "";
    },
    // 图片删除
    handlePictureCardRemove(file) {
      this.$refs.upload.clearFiles();
      // 展示图片上传入口
      this.isShow("inline-block");
      this.formUploadFileList.forEach((res, index) => {
        if (res.uid == file.uid) {
          this.$emit("remove", this.prop, index);
        }
      });
    },
    // 查看文件
    preview(file) {
      if (file.status === "success") {
        this.$emit("preview", this.prop, ...arguments);
      }
    },
    success() {
      this.$emit("success", arguments);
    },
    error() {
      this.$emit("error", arguments);
    },
    handleExceed() {
      this.$message.warning(this.$i18n.t("vue_label_file_singel_upload"));
    },
    beforeUpload(file) {
      let size = file.size / 1024;
      let type = file.name.slice(file.name.lastIndexOf(".") + 1);
      if (size > this.size) {
        this.$message.error(
          file.name + this.$i18n.t("vue_label_file_over_file_size")
        );
        return false;
      } else if (
        this.fileType.length > 1 &&
        this.fileType.indexOf(type) === -1
      ) {
        this.$message.error(
          this.$i18n.t("vue_label_file_wrong_type", { type: type })
        );
        return false;
      } else {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("groupid", "");
        formData.append("libid", "");
        formData.append("parentid", "");
        formData.append("isFromEmail", "");

        if (this.fileType.indexOf("png") !== -1) {
          uploadImg(formData)
            .then((res) => {
              this.$emit("uploadFileSuccess", this.prop, res.data.fileid, true);
              // 隐藏图片上传入口
              this.isShow("none");
              // this.$message.warning(this.$i18n.t("vue_label_file_singel_upload"));
              this.previewFileId = res.data.fileid;
            })
            .catch((err) => {
              return false;
            });
        } else {
          upload(formData)
            .then((res) => {
              this.previewFileId = res.data.fileid;
              this.$emit(
                "uploadFileSuccess",
                this.prop,
                res.data.fileinfoid,
                false
              );
            })
            .catch((err) => {
              return false;
            });
        }
      }
    },
    uploadFile(params) {
      let form = new FormData(); // 创建form对象
      form.append("file", params.file); // 通过append向form对象添加数据
      this.$emit("fileChange", params);
    },
    // 获取上传文件列表
    getUploadFileList() {
      let formData = new FormData();
      let fileList = [];
      let prop = this.prop;
      if (!this.formUploadFileList) {
        this.formUploadFileList = [];
      }
      this.formUploadFileList.forEach((item, index) => {
        // 如果文件没有上传 返回列表
        if (item.status === "ready") {
          // 用数组表示是因为需要批量上传的缘故
          formData.append(this.prop + "[" + index + "]", item.file);
          fileList.push(item);
        }
      });
      return { prop, formData, fileList };
    },
  },
  computed: {
    isShowOpeTip: function () {
      return (
        this.showOperateTip &&
        this.formUploadFileList.length &&
        this.formUploadFileList[0].status === "success"
      );
    },
  },
  watch: {
    fileList() {
      this.getFormUploadFileList();
    },
    // 文件发生改变时候派发的事件
    formUploadFileList(val) {
      if (val && val[0] && val[0].url && this.listType === "picture-card") {
        this.$nextTick(() => {
          // 图片只能上传一张
          // 隐藏图片上传入口
          this.isShow("none");
        });
      }

      let _fileList = this.getUploadFileList();
      if (_fileList.fileList.length > 0) {
        this.$emit("fileChange", _fileList);
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  margin: 0 8px 0px 0;
}

.upload-myself-info {
  position: relative;
  padding-bottom: 48px;
  .upload-text-info {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    height: 48px;
    line-height: 24px;
    color: #e43420;
  }
}

::v-deep .el-icon-close {
  display: none;
}
.upload-myself {
  height: 148px;
}
</style>
