<template>
  <div>
    <el-dialog
      :title="$t('c697',{name:name})"
      :visible.sync="showAddressDialog"
      width="450px"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <ul class="select-account-address" v-if="addressArr.length > 0">
        <li v-for="(item, index) in addressArr" :key="index" :style="{background:(index == 0 && !isNew) ? '#F3F7FF' : ''}" @click="change(item,index)">
          <div class="address">
            <div class="title" :style="{color:index == 0 && !isNew ? '#5789FD' : ''}">{{index == 0 && !isNew ? $t('c878') : $t('label.mr.title.customerAddress')}}：</div>
            <div class="content" :style="{color:index == 0 && !isNew ? '#2D6CFC' : ''}">{{item.address}}</div>
          </div>
          <div class="address">
            <div class="title" :style="{color:index == 0 && !isNew ? '#5789FD' : ''}">{{$t('label.exchange.sync.state.contact')}}：</div>
            <div class="content" :style="{color:index == 0 && !isNew ? '#2D6CFC' : ''}">{{item.contactccname == 'null' ? '' : item.contactccname}}</div>
          </div>
          <div class="address">
            <div class="title" :style="{color:index == 0 && !isNew ? '#5789FD' : ''}">{{$t('label.phone')}}：</div>
            <div class="content" :style="{color:index == 0 && !isNew ? '#2D6CFC' : ''}">{{item.phonenumber == 'null' ? '' : item.phonenumber}}</div>
          </div>
          <svg aria-hidden="true" @click.stop="edit(item)" v-if="isNew || (!isNew && index != 0)">
            <use href="#icon-a-16"></use>
          </svg>
        </li>
      </ul>
      <div v-else class="no-data">{{$t('vue_label_SMS_nodata')}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-plus" @click="addAddress">{{$t('c685')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/**
 * @description: 查看客户账单地址或者收货地址弹窗
 *
 * 功能：
 * 1、展示某个客户的客户地址。
 * 逻辑：
 * 1、根据传入的地址数组弹框渲染客户地址数据。
 */
export default {
  name: "selectAccountAddress",
  props: {
    //弹框展示判断参数
    showAddressDialog: {
      type: Boolean,
      default: false,
    },
    //弹框名称
    name: {
      type: String,
      default: "",
    },
    //地址数据列表
    addressArr:{
      type: Array,
      default:()=>[]
    },
    //判断当前是否为新建对象操作
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    //编辑地址
    edit(val){
      this.$emit('edit',val)
    },
    //添加新地址
    addAddress(){
      this.$emit('addAdderss')
    },
    //关闭弹窗
    handleClose(){
      this.$emit('closedialog')
    },
    //更改地址
    change(val,index){
      if(!this.isNew && index == 0){
        return
      }
      this.$emit('change',val)
    }
  },
};
</script>

<style lang='scss' scoped>
@import "@/style/variables.scss";
::v-deep .el-dialog__footer {
    text-align: center;
  }
  ::v-deep .el-button {
    border: none;
    color: $--color-primary;
  }
  ::v-deep .el-button:hover {
    background: white;
  }
  ::v-deep .el-button:focus {
    background: white;
  }
  ::v-deep .el-dialog__body {
    padding: 0 4px;
    height: 500px;
  }
  .no-data{
    text-align: center;
    margin-top: 220px;
    font-size: 20px;
  }
.select-account-address {
  .address {
    display: flex;
    margin: 5px 0;
    .title {
      width: 90px;
      color: #666;
      flex-shrink: 0;
    }
    .content {
      color: $--color-text-primary;
      word-break: break-all;
    }
  }
  li {
    position: relative;
    padding: 20px 30px;
    border-radius: 4px;
    cursor: pointer;
    svg {
      width: 12px;
      height: 12px;
      position: absolute;
      right: 15px;
      top: 27px;
      display: none;
    }
  }
  li:hover {
    background: $--background-color-base;
    svg {
      display: block;
    }
  }
}
</style>