<template>
  <!-- 表单 可编辑 -->
  <div class="base-form" :style="{ width: width }">
    <!-- :status-icon="statusIcon" -->
    <el-form
      v-loading="formData.length"
      ref="elForm"
      :model="formData"
      class="clearfix"
      :rules="rules"
      :validate-on-rule-change="false"
      :label-position="labelPosition"
      :disabled="disabled"
      size="small"
    >
      <!-- item.fieldType === undefined 时为地址和地理定位child字段 -->
      <template v-for="item in formAttr">
        <!-- devid:方便实施通过divid获取dom -->
        <div
          class="pull-left"
          :devid="item.fieldId ? item.fieldId : item.selectionId"
          :class="
            item.fieldType === 'AD' && item.colNum === 1 ? 'address-field' : ''
          "
          :style="{
            width:
              item.type === 'groupTitle' || item.type === 'customPage'
                ? '100%'
                : item.colNum === 1
                ? oneColumnWidth
                : twoColumnWidth,
            clear: item.position === 'left' ? 'both' : '',
          }"
          v-if="formData && !item.hidden && item.groupShow"
          :key="item.prop"
        >
          <p
            class="groupTitle"
            v-if="item.type === 'groupTitle' && !item.hidden"
            v-html="item.title"
          ></p>
          <p
            class="noDataLine"
            v-else-if="item.type === 'noData' || item.hidden"
          ></p>
          <el-form-item
            v-else-if="!item.noShow"
            :ref="item.prop"
            :prop="item.prop"
            :class="[
              item.className,
              { 'no-label': item.noLabel, remind: item.remind },
            ]"
            :style="item.labelWidth ? 'min-height: 38px;' : 'min-height: 38px;'"
            :label-width="item.labelWidth ? item.labelWidth + 'px' : labelWidth"
          >
            <!-- 自定义表单label -->
            <span slot="label">
              <span class="span-box">
                <span>{{ item.label }}</span>
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  v-show="item.helpText"
                  placement="top-start"
                >
                  <div slot="content" v-html="helpText(item.helpText)"></div>
                  <svg
                    class="icon helpImg"
                    aria-hidden="true"
                    style="display: inline-block"
                  >
                    <use href="#icon-bangzhu-changtai"></use>
                  </svg>
                </el-tooltip>
                <!-- 特殊字段label后显示按钮并可点击 -->
                <span
                  class="pull-right"
                  v-if="showFieldAdditional"
                  style="
                    color: #2d6cfc;
                    cursor: pointer;
                    margin: 0 calc(10% - 10px) 0 10px;
                    fontsize: 14px;
                  "
                  @click="clickFieldButton(item)"
                  >{{ item.button }}</span
                >
                <!-- 特殊字段显示附加内容 -->
                <span class="pull-right" v-if="showFieldAdditional">{{
                  item.additional
                }}</span>
              </span>
            </span>
            <wang-editor
              v-if="item.type === 'kindeditor' && !item.query"
              :ref="item.refKey"
              :contents="formData[item.prop]"
              :disabled="!item.edit"
              @editorContent="
                onContentChange(item.changeEvent, arguments[0], item)
              "
              @LinkarticleClick="LinkarticleClick(item)"
            >
              <template slot="Linkarticle">
                <Linkarticledialog ref="linkarticle"></Linkarticledialog>
              </template>
            </wang-editor>
            <!-- 每个输入框 （文本类型） -->
            <el-input
              v-if="
                (item.type === 'input' ||
                  item.type === 'text' ||
                  item.type === 'password') &&
                !item.query &&
                !item.filterable &&
                !item.isAdress
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="
                item.apiname === 'jyfw'
                  ? 255
                  : item.fieldLength === null || item.fieldLength === undefined
                  ? 1000
                  : Number(item.fieldLength)
              "
              auto-complete="off"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
              :class="
                isOpen
                  ? item.apiname === 'name' &&
                    (specialObjectapi === 'Account' ||
                      objectApi === 'Account' ||
                      prefix === '001')
                    ? 'tianyancha'
                    : item.apiname === 'twitter'
                    ? 'twitter'
                    : item.apiname === 'linkedin'
                    ? 'linkedin'
                    : item.apiname === 'facebook'
                    ? 'facebook'
                    : ''
                  : ''
              "
            >
            </el-input>
            <!-- 地理定位字段 -->
            <!-- <el-button
            class="icon big_map"
            aria-hidden="true"
            v-if="item.type === 'input' && item.isAdress"
            type="primary"
            @click="bigMapBtn(item)"
            :disabled="!item.edit"
          >
            {{ $t("c24") }}
          </el-button> -->
            <span
              v-if="
                isOpen &&
                item.apiname === 'name' &&
                (specialObjectapi === 'Account' ||
                  objectApi === 'Account' ||
                  prefix === '001')
              "
              style="position: absolute"
            >
              <img
                :src="img"
                alt=""
                style="width: 75px; height: 21px; cursor: pointer"
                @click="tianyan"
              />
            </span>
            <!-- 联系人、潜在客户有twitter、linkedin、facebook字段 -->
            <span
              v-if="item.apiname === 'twitter' ? true : false"
              style="position: absolute"
            >
              <svg class="icon linkedinImg" aria-hidden="true">
                <use
                  :href="
                    isTwitter === false ? '#icon-twitter' : '#icon-twitter_blue'
                  "
                ></use>
              </svg>
            </span>
            <span
              v-if="item.apiname === 'linkedin' ? true : false"
              style="position: absolute"
            >
              <svg class="icon linkedinImg" aria-hidden="true">
                <use
                  :href="
                    isLinkedin === false
                      ? '#icon-Linkedin'
                      : '#icon-Linkedin_blue'
                  "
                ></use>
              </svg>
            </span>
            <span
              v-if="item.apiname === 'facebook' ? true : false"
              style="position: absolute"
            >
              <svg class="icon linkedinImg" aria-hidden="true">
                <use
                  :href="
                    isFacebook === false
                      ? '#icon-facebook'
                      : '#icon-facebook_blue'
                  "
                ></use>
              </svg>
            </span>

            <!-- 评分 -->
            <div :class="`stars ${item.apiname}`" v-if="item.type === 'score'">
              <span
                v-for="(star, idx) in Number(item.length) + 1"
                v-show="idx !== 0"
                :class="{ show2: idx <= Number(item.value) }"
                @mouseover="setStar(idx, item)"
                @mouseout="setStar('active', item)"
                @click="setClick(item, idx)"
                :key="idx"
              >
              </span>
            </div>
            <!-- 数字、百分比 -->
            <el-input-number
              v-if="
                item.type === 'percent-number' &&
                !item.query &&
                item.apiname !== 'working_hours'
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :type="item.type"
              :controls="false"
              :placeholder="item.placeholder"
              :min="item.valueInterval.min"
              :max="item.valueInterval.max"
              :precision="item.precision || 0"
              controls-position="right"
              @input.native="onInput"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
            >
            </el-input-number>
            <!-- 项目任务-工作时间 -->
            <el-input
              ref="workinput"
              v-if="item.apiname == 'working_hours'"
              @blur="workethourblur"
              v-model="formData[item.prop]"
              readonly
            >
            </el-input>
            <el-input
              v-if="
                item.type === 'number' &&
                !item.query &&
                item.apiname !== 'working_hours'
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
            >
            </el-input>
            <!--  动态检索项 -->
            <el-autocomplete
              class="el-input"
              v-if="item.query"
              v-model="formData[item.prop]"
              :fetch-suggestions="item.query"
              :placeholder="item.placeholder"
              @select="selecter(item.selecter, ...arguments)"
            >
            </el-autocomplete>
            <!-- ------------------------------------------搜索字段输入框---------------------------------------------- -->
            <!-- 查找/查找多选字段 -->
            <!-- 项目管理系统-新建-实际工作清单菜单-问题/任务字段 -->
            <template v-if="item.apiname === 'taskOrProblem'">
              <SearchRemoteTab
                ref="taskProblem"
                :selectValue="item.value || taskOrProblemVal"
                :project_name="formData.project_name"
                :projectDataType.sync="formData.projectDataType"
                :taskOrProblem.sync="formData.taskOrProblem"
                :member="formData.member"
              />
            </template>
            <template v-else-if="item.apiname === 'working_hours'">
              <WorkingHours
                ref="workinghours"
                :workehoursuser="workehoursuser"
                :workehoursstate="workehoursstate"
                :workehoursend="workehoursend"
                :idd="id"
                @workethoursave="workethoursave"
                @visiblechange="visiblechange"
                :worketwidth="worketwidth"
              />
            </template>
            <template v-else>
              <!-- 也是查找带值 -->
              <el-select
                v-if="
                  item.type === 'remote-select' ||
                  item.type === 'remote-multi-select'
                "
                v-model="formData[item.prop]"
                filterable
                :placeholder="$t('lable.product.please.enter')"
                :disabled="inputCheckIsDisabled(item)"
                :multiple="item.type === 'remote-multi-select' ? true : false"
                clearable
                remote
                :remote-method="(query) => remoteMethod(query, item)"
                :loading="loading"
                :popper-append-to-body="false"
                class="no-suffix"
                :class="{
                  rightIconClass:
                    (item.type === 'remote-select' ||
                      item.type === 'remote-multi-select') &&
                    item.edit,
                }"
                style="width: 100%"
                @focus="focusEvent(item)"
                @change="changeEvent(item.changeEvent, arguments[0], item)"
              >
                <!-- 前往精准搜索 -->
                <p
                  class="searchTipBox"
                  v-if="item.type === 'remote-multi-select'"
                >
                  <span
                    @click="remoteSearch(item)"
                    style="display: inline-block; width: 100%"
                  >
                    <i class="el-icon-search"></i>
                    <span style="padding-left: 10px">
                      {{
                        $t("vue_label_commonobjects_detail_to_precise_search")
                      }}
                    </span>
                  </span>
                </p>
                <!-- 辅助字段需求，需要自定义展示option -->
                <el-option
                  :class="{ auxiliaryFieldBox: nonempty(item.fieldLabel) }"
                  v-for="item in optionList[item.prop]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left; font-size: 14px">{{
                    item.label
                  }}</span>
                  <span v-if="nonempty(item.fieldLabel)" class="auxiliaryField"
                    >{{ item.fieldLabel }}&nbsp;:&nbsp;{{
                      item.fieldValue
                    }}</span
                  >
                </el-option>
                <!-- searchTipBoxFloat:当option中数据为空时，但是有新建权限，此时插入一条空的数据到option中，新建按钮设置定位,空数据value时$creat,label是'  ' -->
                <p
                  v-if="selectCheckIsHiddenNewbulid(item)"
                  class="searchTipBox"
                  :class="{
                    searchTipBoxFloat:
                      optionList[item.prop] &&
                      optionList[item.prop][0] &&
                      optionList[item.prop][0].value === '$creat' &&
                      optionList[item.prop].length === 1,
                  }"
                  @click.stop="newSearchableField(item)"
                >
                  <i class="el-icon-plus"></i>
                  <span style="padding-left: 10px">
                    <!-- 新建 -->
                    {{ $t("label.new") }}
                  </span>
                </p>
              </el-select>
              <!-- 搜索图标 -->
              <span
                v-if="
                  (item.type === 'remote-select' ||
                    item.type === 'remote-multi-select') &&
                  item.edit
                "
                class="remoteBtn"
                :class="{
                  remoteBtnMultiple: item.type === 'remote-multi-select',
                }"
                @click="remoteSearch(item)"
              >
                <!-- 公海池所属公海池在有值情况下，不能编辑，并且不显示搜索按钮 -->
                <i
                  class="el-icon-search"
                  v-if="!inputCheckIsDisabled(item)"
                ></i>
              </span>
            </template>
            <!--任务或事件类型的名称及相关项-->
            <!-- 名称 -->
            <div v-if="item.type === 'eventTypeA'" class="event-item">
              <el-select
                class="event-item-l"
                v-model="eventValueA"
                slot="prepend"
                clearable
                :disabled="!item.edit"
                :placeholder="$t('label.select.please')"
                @change="eventChangeA"
              >
                <el-option
                  v-for="item in item.optionItem"
                  :key="item.id"
                  :label="item.label_name"
                  :value="item.prefix"
                >
                </el-option>
              </el-select>
              <div
                class="event-item-r"
                :class="item.edit === false ? 'dis' : ''"
                @click="item.edit === false ? '' : checkItem(0, item)"
              >
                {{ eventCheckedObjA.name || $t("label.weixin.click.searchs") }}
              </div>
            </div>
            <!-- 相关项 -->
            <div v-if="item.type === 'eventTypeB'" class="event-item">
              <el-select
                class="event-item-l"
                v-model="eventValueB"
                slot="prepend"
                clearable
                :disabled="!item.edit"
                :placeholder="$t('label.select.please')"
                @change="eventChangeB"
              >
                <el-option
                  v-for="item in item.optionItem"
                  :key="item.id"
                  :label="item.label_name"
                  :value="item.prefix"
                >
                </el-option>
              </el-select>
              <div
                class="event-item-r"
                :class="item.edit === false ? 'dis' : ''"
                @click="item.edit === false ? '' : checkItem(1, item)"
              >
                {{ eventCheckedObjB.name || $t("label.weixin.click.searchs") }}
              </div>
            </div>
            <!-- 文件 -->
            <el-input
              v-if="item.type === 'file' && item.fieldType !== 'IMG'"
              v-show="false"
              v-model="formData[item.prop]"
            />
            <!-- 本地文件上传 -->
            <el-select
              v-if="
                item.type === 'file' &&
                item.fieldType !== 'IMG' &&
                (operationType === 'EDIT' || operationType === 'NEW')
              "
              v-model="addFieldType"
              placeholder=""
              :disabled="
                !item.edit ||
                (item.data && item.data.length >= Number(item.length))
              "
              @change="addFile(item, addFieldType)"
            >
              <el-option
                v-for="item in addFileOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span>
                  <svg class="icon localFileIcon" aria-hidden="true">
                    <use :href="item.icon"></use>
                  </svg>
                  {{ item.label }}</span
                >
              </el-option>
            </el-select>
            <!-- 上传的文件列表展示 -->
            <span
              v-if="
                item.type === 'file' && item.fieldType !== 'IMG' && item.data
              "
            >
              <span
                v-for="(file, fileIndex) in item.data"
                :key="file.id"
                style="
                  width: 100%;
                  display: flex;
                  align-item: center;
                  justify-content: space-between;
                "
              >
                <div>
                  <span
                    style="cursor: pointer; margin-right: 10px"
                    @click="openPreview(file)"
                    >{{ file.name }}.{{ file.fileType || file.type }}</span
                  >
                  <!-- 下载 -->
                  <svg
                    class="icon"
                    aria-hidden="true"
                    @click="downLoadFile(file)"
                  >
                    <use href="#icon-xiazai-wenjianxiazai-05"></use>
                  </svg>
                </div>
                <!-- 删除 -->
                <i
                  v-if="item.edit"
                  class="el-icon-close"
                  @click="removeFile(item, fileIndex)"
                  style="cursor: pointer; line-height: 40px"
                ></i>
              </span>
            </span>
            <el-button
              v-if="
                item.type === 'file' &&
                item.fieldType !== 'IMG' &&
                !(operationType === 'EDIT' || operationType === 'NEW')
              "
              :disabled="!item.edit"
              type="primary"
              size="mini"
              @click="addFile(item)"
            >
              <!-- 选择文件 -->
              {{ $t("label.selectfile") }}
            </el-button>
            <!-- 图片引用 -->
            <!-- lightning公式图片在前端隐藏掉 新建时不能显示 -->
            <el-input
              v-if="
                item.type === 'file' &&
                item.fieldType === 'IMG' &&
                item.expressionType === 'url'
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :placeholder="item.placeholder"
              :maxlength="
                item.fieldLength === null || item.fieldLength === undefined
                  ? 1000
                  : Number(item.fieldLength)
              "
              auto-complete="off"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
            >
            </el-input>
            <!-- 图片 -->
            <!-- item.edit 为false则表示不可编辑，则设定只读模式，禁用上传图片和隐藏删除按钮 -->
            <!-- + 新图片按钮 -->
            <AddPicModule
              v-if="
                item.type === 'file' &&
                item.fieldType === 'IMG' &&
                item.expressionType !== 'url'
              "
              :item="item"
              :formData="formData"
              @uploadFileSuccess="uploadFileSuccess"
              :readonly="!item.edit"
            />

            <!-- 长文本 -->
            <el-input
              v-if="item.type === 'textarea'"
              :disabled="!item.edit"
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="handleStr(item)"
              :autosize="{
                minRows: item.minRows || 3,
                maxRows: item.maxRows || 6,
              }"
              v-model="formData[item.prop]"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-input>
            <el-radio-group
              v-model="formData[item.prop]"
              v-if="item.type === 'radio'"
              :disabled="!item.edit"
            >
              <span
                v-for="(child, index) in item.options"
                class="plr-10"
                :key="index"
              >
                <el-radio :label="child.val">{{ child.key }}</el-radio>
              </span>
            </el-radio-group>
            <!-- 多选框  用checkedLabel和 checkedKey来区分取值 -->
            <el-checkbox-group
              v-model="formData[item.prop]"
              v-if="item.type === 'checked'"
              :disabled="!item.edit"
            >
              <template v-for="(checkedItem, index) in item.checkedList">
                <el-checkbox
                  :label="checkedItem[item.checkedKey]"
                  :name="item.prop"
                  @change="changeEvent(item.changeEvent, arguments[0], item)"
                  :key="index"
                >
                  {{ checkedItem[item.checkedLabel] }}
                </el-checkbox>
              </template>
            </el-checkbox-group>
            <!--任务事件 主题字段 特殊 下拉框 -->
            <el-select
              v-if="item.type === 'specialTypeSubject'"
              v-model="formData[item.prop]"
              :disabled="!item.edit"
              clearable
              allow-create
              filterable
              default-first-option
              :placeholder="item.placeholder"
              @visible-change="visibleChange"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="selectBlur($event, item)"
              style="width: 100%"
            >
              <template v-for="(opt, index) in optionList[item.prop]">
                <el-option
                  :key="index"
                  style="overflow: visible"
                  :label="opt.key"
                  :value="opt.val"
                >
                </el-option>
              </template>
            </el-select>
            <!-- 下拉框 -->
            <el-tooltip
              :disabled="
                !item.hasOwnProperty('showCurrencyIntro') ||
                item.showCurrencyIntro
              "
              class="item"
              effect="dark"
              :content="$t('c153')"
              placement="top"
            >
              <!-- 选项列表（单选和多选）和复选框有字段依赖性 -->
              <!-- 编辑和新建时是否禁用判断不同，item.DpendFieldEdit要判断为false -->
              <el-select
                v-if="
                  (item.type === 'select' || item.type === 'multi-select') &&
                  !item.mapFlag &&
                  (item.edittype == 'select' || item.edittype == null)
                "
                v-model="formData[item.prop]"
                :disabled="dpendFieldEdit(item)"
                :clearable="
                  item.apiname === 'fulfill_percent' ||
                  item.apiname === 'complete_percentage' ||
                  item.apiname === 'status' ||
                  item.apiname === 'task_status' ||
                  item.apiname === 'currency'
                    ? false
                    : true
                "
                :multiple="item.type === 'multi-select' ? true : false"
                :filterable="filterable"
                :placeholder="item.placeholder"
                :popper-append-to-body="false"
                @visible-change="visibleChange"
                @change="changeEvent(item.changeEvent, arguments[0], item)"
                @blur="types == 'progress' ? selectBlur($event, item) : ''"
                :class="{ bgColor: item.bgcolor }"
                style="width: 100%"
              >
                <template v-for="(opt, index) in optionList[item.prop]">
                  <template v-if="opt.options">
                    <el-option-group :label="opt.label" :key="index">
                      <el-option
                        v-for="(group, gindex) in opt.options"
                        :key="gindex"
                        style="overflow: visible"
                        :label="group.key"
                        :value="group.val"
                      >
                      </el-option>
                    </el-option-group>
                  </template>
                  <template v-else>
                    <el-option
                      :key="index"
                      style="overflow: visible"
                      :label="opt.key"
                      :value="opt.val"
                    >
                    </el-option>
                  </template>
                </template>
              </el-select>
              <!--
                edittype  select下拉列表  checkbox 复选框列表   radio  单选框列表
                renderStyle    1平铺显示 0每个选项一行
              -->
              <el-checkbox-group
                v-else-if="item.edittype == 'checkbox'"
                v-model="formData[item.prop]"
              >
                <el-checkbox
                  class="inline-checkbox"
                  :class="{ 'block-checkbox': item.renderStyle == '0' }"
                  v-for="(opt, index) in optionList[item.prop]"
                  :key="index"
                  :label="opt.val"
                ></el-checkbox>
              </el-checkbox-group>
              <el-radio-group
                v-else-if="item.edittype == 'radio'"
                v-model="formData[item.prop]"
              >
                <el-radio
                  class="inline-checkbox"
                  :class="{ 'block-checkbox': item.renderStyle == '0' }"
                  v-for="(opt, index) in optionList[item.prop]"
                  :key="index"
                  :label="opt.val"
                ></el-radio>
              </el-radio-group>
            </el-tooltip>
            <!-- 复选框 -->
            <el-checkbox
              v-if="item.type === 'checkbox'"
              :disabled="!item.edit"
              v-model="formData[item.prop]"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
              <!-- 客户地址复选框展示提示 -->
              <span
                class="checkbox-word"
                v-if="
                  item.defaultAddress &&
                  (formData[item.prop] || formData[item.prop] == '1')
                "
                >{{
                  item.apiname == "billingaddress" ? $t("c604") : $t("c607")
                }}</span
              >
            </el-checkbox>
            <!-- 级联选择器 -->
            <el-cascader
              v-if="item.type === 'cascader'"
              :disabled="!item.edit"
              v-model="formData[item.prop]"
              :options="item.options"
              style="width: 100%"
              filterable
              :show-all-levels="item.showAllLevels || false"
            >
            </el-cascader>
            <!-- 日期 -->
            <el-date-picker
              v-if="item.type === 'date'"
              :disabled="!item.edit"
              :editable="false"
              v-model="formData[item.prop]"
              type="date"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy-MM-dd"
              :format="dateFormat"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 日期时间 -->
            <el-date-picker
              v-if="item.type === 'datetime'"
              :disabled="!item.edit"
              :editable="false"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              :format="datetimeFormat"
              v-model="formData[item.prop]"
              type="datetime"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 时间 -->
            <el-time-select
              v-if="item.type === 'time'"
              :disabled="!item.edit"
              value-format="HH:mm:ss"
              :format="timeFormat"
              v-model="formData[item.prop]"
              :picker-options="{ start: '00:00', step: '00:15', end: '23:45' }"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-time-select>
            <!-- 年份 -->
            <el-date-picker
              v-if="item.type === 'dateYear'"
              :disabled="!item.edit"
              :editable="false"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy"
              v-model="formData[item.prop]"
              type="year"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- item {{item.fieldType}} -->
            <!-- 地址字段处理 -->
            <AddressFiled
              ref="AddressFiled"
              v-if="item.fieldType === 'AD'"
              :item="item"
              :optionList="optionList"
              :operationType="operationType"
              :isNewBuild="isNewBuild"
              :id="id"
              :types="types"
              :objectApi="objectApi"
              :userInfo="userInfo"
              :showAddressDialog="showAddressDialog"
              :addressType="addressType"
              :formData="formData"
              :filterable="filterable"
              @changeEvent="changeEvent"
              @selectBlur="selectBlur"
              @bigMapBtnFn="bigMapBtnFn"
              @handleBlurInChild="handleBlurInChild"
            />

            <!-- 提醒 -->
            <span class="remind-text" v-if="item.remind">
              {{ item.remind }}
            </span>
            <!-- 后台验证 不符合规则 提示 wwwjjj -->
            <span class="errormessage-text" v-if="item.error">{{
              item.error
            }}</span>
          </el-form-item>
        </div>
      </template>
    </el-form>

    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <!-- 防止search-table组件上出现两个滚动条，禁止设置高度，可以设置最小高度 -->
      <div
        :style="{
          'min-height': dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :dialogBodyHeight="dialogBodyHeight"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-objapi="relevantObjApi"
          :relevant-prefix="relevantPrefix"
          :optionCheckedArr="optionCheckedArr"
          :newInvoiceInfo="newInvoiceInfo"
          :projectId="projectId"
          :milestone="milestone"
          :remoType="remoType"
          :taskOrquestion="taskOrquestion"
          :relevantObj="relevantObj"
          @changeSelect="changeSelect"
          @setFieldReltaion="setFieldReltaion"
        />
      </div>
    </el-dialog>

    <!-- 天眼查 -->
    <tian-yan-cha
      ref="tian"
      @setTianyanValuees="setTianyanValuees"
    ></tian-yan-cha>

    <!-- 高德/谷歌地图 -->
    <el-dialog
      title=""
      :width="mapWidth"
      :top="mapTop"
      :visible.sync="bigMapDialog"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
      custom-class="big-view-box"
    >
      <el-button-group class="btn_map" v-if="mapTop === '6%'">
        <!-- 高德地图 谷歌地图-->
        <!-- 隐藏切换按钮 -->
        <!-- <el-button
          :style="{
            background: isMap ? '#006DCC' : '',
            color: isMap ? '#fff' : '#000',
          }"
          @click="mapBtnChange('gd')"
          >{{ $t("label.gaode.map") }}</el-button
        >
        <el-button
          :style="{
            background: !isMap ? '#006DCC' : '',
            color: !isMap ? '#fff' : '#000',
          }"
          @click="mapBtnChange('gg')"
          >{{ $t("label.google.map") }}</el-button
        > -->
      </el-button-group>
      <!-- 高德/谷歌地图 -->
      <map-group
        ref="mapGroup"
        :mapkey="mapkey"
        :pointValue="clearItemArr.gdPointValue"
        :ggPointValue="clearItemArr.ggPointValue"
        :lineIndex="lineIndex"
        :isInfoMap="isInfoMap"
        :isMap="isMap"
        @chooseLocation="chooseLocation"
        @clearLocation="clearLocation"
        @bigClose="bigClose"
      ></map-group>
    </el-dialog>
    <!-- 更改地址弹框 -->
    <selectAccountAddress
      :showAddressDialog="showAddressDialog"
      :name="accountName"
      :addressArr="addressArr"
      :isNew="operationType == 'NEW' || isNewBuild"
      @closedialog="closeAddressdialog"
      @change="changeAccountAddress"
      @addAdderss="addAdderss"
      @edit="editAddress"
    ></selectAccountAddress>
    <!-- 订单发票详情页新建客户地址 -->
    <create-edit-obj
      ref="creatAddress"
      :prefix="'045'"
      :id="dialogNewId"
      :objectApi="'cloudccustomeraddress'"
      :relation-field-id="'ffe328customeradd012'"
      :object-name="$t('label.mr.title.customerAddress')"
      type="active"
      :relative-record-id="formData.accountid || formData.account_name"
    ></create-edit-obj>
    <!-- 文件预览 -->
    <file-preview
      :show="showPreview"
      :showData="showPreviewData"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>
  </div>
</template>

<script type="text/ecmascript-6">
/**
 * 通用表单
 * 功能：
 * 1、根据传入的表单字段信息展示不同的字段样式
 * 2、对查找字段进行查找、快速新建
 * 3、天眼查查询并回填数据
 * 4、高德和谷歌地图引用并回填数据
 */
import FormUpload from "@/components/Upload/formUpload";
import { IEVersion } from "@/utils/brower";
import * as Time from "@/utils/time";
import * as CommonObjApi from "./api";
import WangEditor from "../wangEditor/index.vue";
import tianYanCha from "../../components/Tianyancha";
import SearchRemoteTab from "./SearchRemoteTab";
import * as projectApi from "./api"; //项目管理系统相关api
import Linkarticledialog from "@/views/Knowledgearticles/Linkarticledialog.vue"; // 富文本内部链接
import MapGroup from "@/components/Map/index.vue";
import WorkingHours from "@/components/Form/components/workingHours.vue";
import selectAccountAddress from "@/components/SelectAddress/selectAccountAddress.vue"; //选择客户地址弹框
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue"; //新建编辑客户地址弹框
import operator from "@/config/enumCode/operator.js";
import INPUTTYPE from "@/config/enumCode/inputType.js";
import mixinMethods from "./mixins/index";
import filePreview from "@/components/FileView/filePreview";
export default {
  name: "base-form",
  mixins: [mixinMethods],
  props: {
    // 表单中所有select选择器选项集合
    optionList: {
      type: Object,
      default: () => ({}),
    },
    // 是否是新建数据
    isNewBuild: {
      type: Boolean,
      default: false,
    },
    // 表单数据所属记录id
    id: {
      type: String,
      default: "",
    },
    // 使用位置是否在进度条下
    types: {
      type: String,
      default: "",
    },

    // 表单数据所属对象api
    objectApi: {
      type: String,
      default: "",
    },
    // 表单数据操作类型,有NEW、EDIT等
    operationType: {
      type: String,
      default: "",
    },
    // 是否展示字段附加信,包括按钮和展示信息
    showFieldAdditional: {
      type: Boolean,
      default: false,
    },
    relatedlistIds: {
      type: String,
      default: "",
    },
    // 是否是订单生成发票页面
    isOrderPage: {
      type: Boolean,
      default: false,
    },
    // 是否是伙伴云
    isPartner: {
      type: Boolean,
      default: false,
    },

    // 表单域标签的位置
    labelPosition: {
      type: String,
      default: "left",
    },
    // 整个表单宽度
    width: {
      type: String,
    },
    // 几列布局,有2、4、6这几个值
    columnNum: {
      type: Number,
      default: 2,
    },
    // 一列占宽百分比
    oneColumnWidth: {
      type: String,
      default: "100%",
    },
    // 两列占宽百分比
    twoColumnWidth: {
      type: String,
      default: "50%",
    },
    // 是否在输入框中显示校验结果反馈图标(不需要反馈了)
    statusIcon: {
      type: Boolean,
      default: false,
    },
    // 表单数据所属对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 表单数据对象
    formAttr: {
      type: Array,
      default: () => [],
    },
    // 表单数据记录所属对象api,用于判断是否为事件类型，动态更改时间
    specialObjectapi: {
      type: String,
      default: "",
    },
    // 表单域标签的宽度
    labelWidth: {
      type: [Number, String],
      default: "150px",
    },
    // 鼠标移出输入框绑定事件
    handleBlur: {
      type: [Function, undefined],
      default: undefined,
    },
    // 值发生变化时是否自动刷新表单
    autoRefresh: {
      type: Boolean,
      default: true,
    },
    // 是否禁用该表单内的所有组件。若设置为 true，则表单内组件上的 disabled 属性不再生效
    disabled: {
      type: Boolean,
      default: false,
    },

    // 是否是启用外部联系人
    contactes: {
      type: String,
    },
    // 公海池编辑状态
    isClientPoolEditStatus: {
      type: Boolean,
    },

    // 关联记录id
    relativeRecordId: {
      type: String,
      default: "",
    },

    // 表单数据所属记录布局id
    layoutId: {
      type: String,
      default: "",
    },
    // 依赖字段数据
    dependContainer: {
      type: Array,
    },
  },
  components: {
    FormUpload,
    WangEditor,
    tianYanCha,
    SearchRemoteTab,
    Linkarticledialog,
    MapGroup,
    WorkingHours,
    selectAccountAddress,
    createEditObj,
    AddressFiled: () => import("./components/AddressFiled.vue"),
    AddPicModule: () => import("./components/AddPicModule.vue"),
    filePreview,
  },
  data() {
    return {
      // 下拉框和级联选择器是否可检索
      filterable: true,
      // 表单数据对象
      formData: {},
      // 判断当前更改地址是账单地址还是收货地址
      addressType: "",
      // 地址字段弹框展示
      showAddressDialog: false,
      // 当前登录用户信息
      userInfo: {},
      // 记录当前查找的查找对象，快速新建的时候使用
      relevantObj: {},
      // 查找字段搜索内容，用于快速新建带值
      searchValueText: "",
      filedId: "",
      // 文件预览控制dom显隐
      showPreview: false,
      // 文件预览数据
      showPreviewData: {},
      // 文件上传类型：
      addFieldType: "",
      // 文件上传下拉数据
      addFileOptions: [
        {
          value: "1",
          label: this.$i18n.t("label.chat.file.upload.info"),
          icon: "#icon-a-Uploadfiles",
        },
        {
          value: "2",
          label: this.$i18n.t("vue_label_file_libraray"),
          icon: "#icon-a-DocumentLibrary",
        },
      ],
      // 天眼查是否开启
      isOpen: false,
      // 查找字段快速新建数据为发票时，对应的信息
      newInvoiceInfo: {},
      // 业务机会对象可能性、预测类别随阶段变化显示对应的值
      possibilityList: [],
      // 相关项及名称
      defaultPrefix: {},
      // 任务或者事件对象名称字段显示数据
      eventCheckedObjA: {},
      // 任务或者事件对象相关项字段显示数据
      eventCheckedObjB: {},
      // 区分名称和相关项赋值
      eventIndex: -1,
      // 任务或者事件对象名称字段相关对象
      eventValueA: "",
      // 任务或者事件对象相关项字段相关对象
      eventValueB: "",
      // 事件对象中开始时间与结束时间的时间差
      timeDiff: 0,
      // 日期类型字段格式化
      dateFormat: Time.dateFormat($cookies.get("countryCode")),
      // 日期时间类型字段格式化
      datetimeFormat: Time.datetimeFormat($cookies.get("countryCode")),
      // 时间类型字段格式化
      timeFormat: Time.timeFormat("USA"),
      // 字段为空校验
      isEmpty: (rule, value, callback) => {
        if (value && value.toString().trim() === "") {
          return callback(new Error(this.$i18n.t("label.empty.field")));
        } else {
          callback();
        }
      },
      // 电子邮箱地址校验
      isEmail: (rule, value, callback) => {
        const reg =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value && !reg.test(value)) {
          // '请输入正确的电子邮箱地址'
          return callback(
            new Error(this.$i18n.t("label_emailtocloudcc_redmessage1"))
          );
        } else {
          callback();
        }
      },
      // 数字校验
      isNumber: (rule, value, callback) => {
        if (value !== undefined && isNaN(value)) {
          // 您输入的不是有效数字
          return callback(new Error(this.$i18n.t("label.field.input.number")));
        } else {
          callback();
        }
      },
      // 校验twitter链接
      twitter: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("twitter.com") === -1) {
            this.isTwitter = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.twittererror"))
            );
          } else {
            this.isTwitter = true;
            callback();
          }
        }
      },
      // 校验linkedin链接
      linkedin: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("linkedin.com") === -1) {
            this.isLinkedin = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.linkedinerror"))
            );
          } else {
            this.isLinkedin = true;
            callback();
          }
        }
      },
      // 校验facebook链接
      facebook: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("facebook.com") === -1) {
            this.isFacebook = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.facebookerror"))
            );
          } else {
            this.isFacebook = true;
            callback();
          }
        }
      },
      // 查找字段相关对象id
      relevantObjId: "",
      // 查找字段相关对象api
      relevantObjApi: "",
      // 查找字段相关对象前缀
      relevantPrefix: "",
      // 查找多选字段选中的数据集合(打开弹窗时回显用)
      optionCheckedArr: [],
      // 是否展示查找/查找多选弹窗
      showSearchTable: false,
      // 查找/查找多选字段id
      fieldId: "",
      // 查找/查找多选字段弹窗中的表格是否允许多选,字段类型为查找多选时才可多选
      checked: false,

      // 表单验证规则
      rules: {},

      // 工时字段远程搜索loading
      loading: false,
      // 远程搜索用于显示的数组
      filterOptions: [],
      // 文件类型字段上传文件大小(M)
      fileSize: 1024,
      // 查找/查找多选弹窗主体高度
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      // 评分类型字段元素集合
      stars: null,
      // 天眼查图标
      img: require("@/assets/images/tianyancha.svg"),

      //是否为项目管理系统
      isProjectSystem: false,
      // Twitter字段值是否为有效的Twitter链接
      isTwitter: false,
      // Linkedin字段值是否为有效的Linkedin链接
      isLinkedin: false,
      // Facebook字段值是否为有效的Facebook链接
      isFacebook: false,
      // 选中的任务/问题的值
      taskOrProblemVal: "",
      // 查找字段为项目时,数据id,查找字段时使用
      projectId: "",
      // 查找多选字段的apiname,查找字段时使用
      milestone: "",
      // 从所属项目名称打开精准搜索
      projectNameFlag: "",
      // 查找/查找多选/主详字段类型
      remoType: "",

      // 谷歌/高德地图密钥
      mapkey: "",
      // 谷歌/高德动态地图id
      lineIndex: Math.ceil(Math.random() * 100),
      // 谷歌/高德地图弹窗宽度
      mapWidth: "80%",
      // 谷歌/高德地图弹窗距页面上方距离
      mapTop: "6%",

      // 是否是详情页地图
      isInfoMap: false,
      // 任务、问题id
      taskOrquestion: "",
      // 项目任务-工作时间-负责人
      workehoursuser: [],
      // 项目任务-工作时间-开始时间
      workehoursstate: "",
      // 项目任务-工作时间-结束时间
      workehoursend: "",
      // 项目任务-工作时间-弹框宽度
      worketwidth: 0,

      // 订单发票详情页新建客户地址时记录id
      dialogNewId: "",
      // 判断是否是新建
      isNew: false,
      // 资源属性字段选项
      resourceAttributeFields: [],
    };
  },
  created() {
    this.queryLimit();
    this.filterFormData(true);
    // 业务机会对象可能性随阶段变化显示对应的值
    if (this.prefix === "002" && this.formData["jieduan"] !== undefined) {
      this.getKNXSelectValue();
    }
    // 天眼查是否启用设置，只有'客户'菜单下才需要天眼查
    if (this.objectApi === "Account") {
      this.isOpenFun();
    }
    // 如果是edge浏览器 将select可搜索改为false
    if (IEVersion() === "edge") {
      this.filterable = false;
    }
    this.listenStorage();
    this.getUserInfo();
  },
  beforeDestroy() {
    this.$Bus.$off("deliver-customer-address", this.deliverCustomerAddress);
    this.$bus.$off("windowResize", this.windowResize);
    window.removeEventListener("storage", this.addRelevantObjectType);
  },
  mounted() {
    //接收客户地址
    this.$Bus.$on("deliver-customer-address", this.deliverCustomerAddress);
    if (this.layoutId && this.layoutId !== "") {
      this.currencyEdit();
    }
    //在里程碑、任务、子任务、服务预约中开始时间、结束时间默认为当前时间
    if (
      (this.prefix == "p02" ||
        this.prefix == "p03" ||
        this.prefix == "p05" ||
        this.prefix == "p01" ||
        this.prefix == "p12") &&
      !this.formData.start_date &&
      !this.formData.end_date
    ) {
      this.$nextTick(() => {
        this.formData.start_date = this.formatDateTime(1);
        this.formData.end_date = this.formatDateTime(1);
      });
    } else if (this.prefix == "p10" && !this.formData.duedate) {
      this.$nextTick(() => {
        this.formData.duedate = this.formatDateTime(1);
        //实际工作清单 计费 默认为 是
        this.formData.settlement_type = "是";
      });
    }
    // 资源使用时新增任务弹窗回显时间和负责人
    if (this.$store.state.projectManage.relationResource) {
      let data = this.$store.state.projectManage.relationResource;
      // 项目负责人带值
      this.$set(this.formData, "principal", [data.curMemberId]);
      this.$set(this.formData, "cloudccprojectmember", [data.curMemberId]);
      let options = [
        {
          value: data.curMemberId,
          label: data.curMemberName,
        },
      ];
      this.$parent.$set(this.optionList, "principal", options);
      this.$parent.$set(this.optionList, "cloudccprojectmember", options);
      // 开始结束日期带值
      this.formData.end_date = this.formData.start_date = data.curDate;
      // 项目名称带值
      this.formData.actualstarttime = this.formData.actualendtime =
        data.curDate;
      let options1 = [
        {
          value: data.proId,
          label: data.proName,
        },
      ];
      this.$parent.$set(this.optionList, "their_project", options1);
      this.$set(this.formData, "their_project", data.proId);
      this.$store.commit("updateRelationResource", null);
    }

    this.$bus.$on("windowResize", this.windowResize);
    this.newInvoiceInfo = {
      prefix: this.prefix,
      isOrderPage: this.isOrderPage,
    };
  },
  methods: {
    /**
     * 打开文件预览
     */
    async openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = item;
      // 传入按钮如果不需要按钮，可以传空数组
      this.$refs.previewFile.handleBtn([]);
    },
    /**
     * 文件预览关闭
     */
    closePreview() {
      // 列表打开预览
      this.showPreview = false;
    },
    /**
     * 判断依赖字段是否可编辑
     * 1、编辑时需要判断DpendFieldObj.DpendFieldEdit是否是布尔false
     * @param {object} DpendFieldObj :依赖字段信息
     * @return {Boolean} flag:是否禁用
     */
     dpendFieldEdit(DpendFieldObj) {
      let flag = false;
      if (Object.prototype.toString.call(DpendFieldObj) === "[object Object]") {
        flag = !DpendFieldObj.edit;
        if (DpendFieldObj.isDpendFieldid) {
          // if (this.operationType === "EDIT") {
          //   flag = DpendFieldObj.DpendFieldEdit === false;
          // } else {
          //   flag = !DpendFieldObj.DpendFieldEdit;
          // }
          flag = !DpendFieldObj.DpendFieldEdit;
        }
      }
      return flag;
    },

    windowResize(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    deliverCustomerAddress(res) {
      if (this.addressType == "shipping" || this.addressType == "billing") {
        this.changeAccountAddress(res[0]);
      }
    },
    /**
     * 内部组件处理鼠标移出事件
     * @param {Object} item 字段信息
     * @param {String} val 字段值
     * @param {Object} formData 表单绑定的数据
     */
    handleBlurInChild(item, val, formData) {
      // 去除收尾空格
      if (typeof val === "string") {
        formData[item.prop] = val.trim();
      }
      // 知识文章url名称为空时使用name值
      if (formData.url_name == null || formData.url_name == "") {
        // 解决formData.name空值报错
        if (formData && typeof formData.name === "string") {
          formData.url_name = formData.name.toString().replace(/ /g, "-");
        }
      }
      // this.handleBlur && this.handleBlur(...arguments)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent =
        item.changeEvent !== undefined ? item.changeEvent.split(",") : [];
      changeEvent.forEach((eventItem) => {
        if (eventItem === "control") {
          eventItem && this.$emit(eventItem, val, item.fieldId, this.formData);
        } else {
          eventItem && this.$emit(eventItem);
        }
      });
    },
    // 上传完成，更新模板
    uploadFileSuccess({ data, prop }) {
      // 图片字段字符串
      let idStr = data.map((item) => item.id).join(";");
      this.$set(this.formData, prop, idStr);
    },
    //手动输入值
    selectBlur(e, item) {
      this.formData[item.prop] = e.target.value;
    },
    /**
     * 工作规则和服务目标新建编辑的时候字段依赖
     * @param {String} callback 字段绑定的回调函数名称
     * @param {String} selectVal 字段值
     * @param {Object} selectItem 字段信息
     * @param {String} fieldType 字段类型
     */
    workAndServiceGoal(callback, selectVal, selectItem) {
      // 工作规则处理
      if (
        this.objectApi == "WorkRule" &&
        selectItem.apiname == "resourcespropertyfield"
      ) {
        // 选的字段类型
        let curValue = this.resourceAttributeFields.find((item) => {
          return item.id === selectVal;
        });
        // 查找运算符
        let conditions =
          operator.TYPE[curValue.schemefieldType].getScreeningOpeTypes();
        this.formAttr.forEach((item) => {
          // 字段运算符
          if (item.apiname == "fieldoperator") {
            item.type = "select";
            let options = conditions.map((ele) => {
              return {
                key: ele.name,
                val: ele.id,
              };
            });
            this.$parent.$set(this.optionList, item.prop, options);
          } else if (item.apiname == "resourcespropertyvalue") {
            // 字段类型对应不同的样式
            item.type = INPUTTYPE[curValue.schemefieldType];
            // 资源属性值
            if (
              curValue.schemefieldType == "L" ||
              curValue.schemefieldType == "Q"
            ) {
              CommonObjApi.getSelectValue({ fieldId: selectVal }).then(
                (res) => {
                  if (res.result && res.returnCode === "1") {
                    let options = [];
                    res.data.forEach((item) => {
                      options.push({
                        val: item.codekey,
                        key: item.codevalue,
                      });
                    });
                    this.$set(this.optionList, item.prop, options);
                  } else {
                    this.$message.error(res.returnInfo);
                  }
                }
              );
            }
            if (curValue.schemefieldType == "P") {
              let decimal =
                curValue.decimalPlaces !== undefined &&
                curValue.decimalPlaces !== null
                  ? 1 / Math.pow(10, Number(curValue.decimalPlaces))
                  : 0;
              item.valueInterval = {
                min:
                  0 -
                  Math.pow(
                    10,
                    curValue.schemefieldLength - curValue.decimalPlaces - 1
                  ) +
                  decimal,
                max:
                  Math.pow(
                    10,
                    curValue.schemefieldLength - curValue.decimalPlaces - 1
                  ) - decimal,
              };
            }
          }
        });
      }
      // 服务目标，自定义目标类型 ----开始
      if (
        this.objectApi == "ServiceGoal" &&
        selectItem.apiname == "customobjectivetype"
      ) {
        // 注：接口返回的value就是中文
        if ("资源资格" == selectVal) {
          // 资格字段
          this.formAttr.forEach((item) => {
            if (item.apiname == "qualificationfield") {
              item.type = "select";
              this.resourceAttributeFields = [];
              let options = [];
              CommonObjApi.getQualificationFields().then((res) => {
                res.data.forEach((itemo) => {
                  // 留着选中后匹配类型用
                  this.resourceAttributeFields.push(...itemo.fieldList);
                  // 组合数据
                  let obj = {};
                  obj.label = itemo.objName;
                  obj.options = itemo.fieldList.map((items) => {
                    return {
                      key: items.labelName,
                      val: items.id,
                    };
                  });
                  // 给资源属性字段赋值选项-且分组
                  options.push(obj);
                });
                this.$parent.$set(this.optionList, item.prop, options);
              });
            }
          });
        } else {
          // 资格字段
          this.formAttr.forEach((item) => {
            if (item.apiname == "qualificationfield") {
              item.type = "input";
            }
          });
        }
      }
      // 服务目标----结束
    },
    /**
     * 表单字段值发生变化时触发的事件
     * @param {String} callback 字段绑定的回调函数名称
     * @param {String} selectVal 字段值
     * @param {Object} selectItem 字段信息
     * @param {String} fieldType 字段类型
     */
    changeEvent(callback, selectVal, selectItem, fieldType) {
      /**
       * - 那个 最终客户名称的 callback 传递的是 lookupValue 所以走的是最下面的 else
       * - 然后传递到 create-edit-obj 组件，组件中监听到 lookupValue 事件
       * - 然后又tn调用的 getALLLookupValue 方法
       * - getALLLookupValue 方法又调用的接口传入的 当前formdata中的数据，通过轮询接口才拿到地址信息中每一个输入框的值
       */

      // 工作规则和服务目标新建编辑的时候字段依赖
      this.workAndServiceGoal(callback, selectVal, selectItem);
      if (selectVal && selectVal[0] && selectVal[0] === "$creat") {
        // 点击的是假数据
        selectVal = [];
        return;
      }
      // 如果是币种
      if (selectItem.apiname === "currency") {
        this.$emit("changeCurrency", selectVal);
      }
      // 如果是价格手册
      if (selectItem.apiname === "pricebook2id") {
        this.$emit("changePricebook", selectVal);
      }
      // 实际工作清单项目名称清除--成员清空
      if (this.prefix === "p10" && !this.formData.project_name) {
        this.formData.member = "";
        this.$parent.$set(this.optionList, "member", []);
      }
      // 知识文章url名称为空时使用name值
      if (selectItem.apiname === "url_name") {
        if (this.formData.url_name == "") {
          this.formData.url_name = this.formData.name.replace(/ /g, "-");
        }
      }
      //项目管理弹窗上添加提示
      if (
        (selectItem.apiname === "inform_principal" ||
          selectItem.apiname == "notice_principal") &&
        selectVal == this.$i18n.t("label.tabpage.noz") //"否"
      ) {
        //当您选择否时，将无法通知负责人！
        this.$alert(
          this.$i18n.t("label.projectManagement.the.person.not.be.informed")
        );
      }
      if (
        selectItem.apiname === "remind_principal" &&
        selectVal == this.$i18n.t("label.tabpage.noz") //"否"
      ) {
        //当您选择“否”时，提醒频率将无法生效
        this.$alert(
          this.$i18n.t("label.projectManagement.alert.frequency.will.not.work")
        );
      }
      if (
        (selectItem.apiname === "complete_percentage" ||
          selectItem.apiname === "fulfill_percent") &&
        selectVal == "0%"
      ) {
        this.formData.status = "未开始"; //"未开始"
        this.formData.task_status = "未开始"; //"未开始"
      } else if (
        (selectItem.apiname === "complete_percentage" ||
          selectItem.apiname === "fulfill_percent") &&
        selectVal == "100%" &&
        this.prefix === "p03"
      ) {
        // 项目任务对象特殊处理
        this.formData.status = "已完成"; //已完成
        this.formData.task_status = "已完成"; //已完成
      } else if (
        (selectItem.apiname === "complete_percentage" ||
          selectItem.apiname === "fulfill_percent") &&
        selectVal == "100%"
      ) {
        this.formData.status = "完成"; //完成
        this.formData.task_status = "完成"; //完成
      } else if (
        selectItem.apiname === "complete_percentage" ||
        selectItem.apiname === "fulfill_percent"
      ) {
        this.formData.status = "进行中"; //"进行中"
        this.formData.task_status = "进行中"; //"进行中"
      }
      // 业务机会对象可能性、预测类别随阶段变化显示对应的值
      if (this.prefix === "002" && selectItem.apiname === "jieduan") {
        let knx = this.possibilityList.find((item) => {
          return item.val === selectVal;
        });
        if (knx !== undefined) {
          // 强制改可能性和预测类别的值 --- 接口处理
          // this.$set(this.formData, 'knx', knx.knx)
          // this.$set(this.formData, 'forecasttype', knx.forecasttype)
          if ("knx" in this.formData) {
            this.formData["knx"] = knx.knx;
          }
          if ("forecasttype" in this.formData) {
            this.formData["forecasttype"] = knx.forecasttype;
          }
        }
      }

      // 日期时间字段处理
      if (fieldType === "datetime") {
        if (
          this.formData[`${selectItem.prop}Date`] &&
          this.formData[`${selectItem.prop}Time`]
        ) {
          this.$set(
            this.formData,
            selectItem.prop,
            `${this.formData[`${selectItem.prop}Date`]} ${
              this.formData[`${selectItem.prop}Time`]
            }`
          );
        } else {
          this.$set(this.formData, selectItem.prop, "");
        }
      }
      // callback && callback(selectVal, selectItem, this.formData)
      // 字段依赖性，依赖字段清空
      if (selectItem.DpendFieldEdit) {
        this.$emit("control", selectVal, selectItem, "$cancelDependField");
      }
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      // 字段依赖性，依赖字段清空
      if (selectItem.DpendFieldEdit && !selectVal) {
        this.$emit("control", selectVal, selectItem, "$cancelDependField");
      }
      let changeEvent = callback !== undefined ? callback.split(",") : [];
      if (selectItem.prop !== "recordtype") {
        changeEvent.forEach((eventItem) => {
          if (!eventItem) return;
          if (eventItem === "control") {
            eventItem &&
              this.$emit(
                eventItem,
                selectVal,
                selectItem.fieldId,
                this.formData
              );
          } else if (eventItem === "getCity") {
            this.$refs?.AddressFiled[0]?.addressChange({
              childrenVal: null,
              arg: null,
            });
            /**
             * 根据省份 -> 获取市区
             */
            eventItem &&
              this.$emit(
                "getCity",
                selectVal,
                selectItem.fieldId,
                this.formData
              );
          } else if (eventItem === "getCounty") {
            /**
             * 根据市区 -> 获取县城镇
             */
            eventItem &&
              this.$emit(
                "getCounty",
                selectVal,
                selectItem.fieldId,
                this.formData
              );
          } else {
            eventItem &&
              this.$emit(eventItem, selectVal, selectItem, this.formData);
          }
        });
      }
      this.$forceUpdate();
      // 事件类型自动更改结束时间
      if (
        this.specialObjectapi === "Event" &&
        this.formData.endtime &&
        this.formData.begintime
      ) {
        // 如果更改项为开始时间自动更改结束时间
        if (selectItem.prop === "begintime") {
          this.autoEventDate(this.formData.begintime);
        }
        // 如果更改项为结束时间重新计算时间差
        if (selectItem.prop === "endtime") {
          if (
            this.timeFn(this.formData.begintime, this.formData.endtime) >= 0
          ) {
            this.timeDiff = this.timeFn(
              this.formData.begintime,
              this.formData.endtime
            );
          } else {
            this.formData.endtime = "";
            this.$message({
              showClose: true,
              type: "warning",
              // '结束时间不应早于开始时间'
              message: this.$i18n.t("label.appointment.wizard.notif1"),
            });
          }
        }
      }
      if (selectItem.fieldType === "MR") {
        this.$emit("changeMR", selectVal, selectItem);
        this.optionCheckedArr = [];
        //获取表格回显选中数据
        // selectVal.forEach(val => {
        //   this.optionCheckedArr.push({ id: val })
        // })
        // 一下部分使用了未知变量selectlist，暂时注解调，勿删
        // let selectoption = [...selectlist].filter((res) =>
        //   [...selectVal].some((item) => res.value == item)
        // );
        // selectoption.map((item, index) => {
        //   this.optionCheckedArr.push({ id: item.value, name: item.label });
        // });
      }

      /**
       * 发射所有事件后，都要发送次事件给 src\views\commonObjects\components\create-edit-obj.vue
       * 功能:
       *   选择新的省市区县后清除之前已选数据
       */
      this.$emit("changeEventEmitToParent", {
        selectVal,
        fieldId: selectItem.fieldId,
        formData: this.formData,
      });
    },
    /**
     * 判断值是否非空
     * 1、数字0认为是有值
     * @param {String | Number} target:判断目标值
     * @param {Boolean} 是否非空
     */
    nonempty(target) {
      let ary = ["null", "undefined", "", null, undefined];
      return !ary.includes(target);
    },
    /**
     * 点击表单字段label中的按钮
     * @param {Object} attr 按钮所属的字段信息
     */
    clickFieldButton(attr) {
      this.$emit(attr.buttonClick, attr, this.formData);
    },
    /**
     * isOpenFun:获取天眼查是否启用设置
     */
    isOpenFun() {
      CommonObjApi.isOpen().then((res) => {
        this.isOpen = res.data.isOpen;
      });
    },

    /**
     * 关闭更改地址字段弹窗
     */
    closeAddressdialog() {
      this.showAddressDialog = false;
    },
    /**
     * 更改客户地址
     * @param {Object} val 地址字段信息
     */
    changeAccountAddress(val) {
      //将地址各个字段回显
      this.formData[this.clearItemArr.prop + "00"] = val.address00;
      this.formData[this.clearItemArr.prop + "01"] = val.address01;
      this.formData[this.clearItemArr.prop + "02"] = val.address02;
      this.formData[this.clearItemArr.prop + "03"] = val.address03;
      this.formData[this.clearItemArr.prop + "04"] = val.address04;
      //将联系人与联系人电话回显
      if (
        this.clearItemArr.prop == "shippingaddress" ||
        this.clearItemArr.prop == "ship_address"
      ) {
        let name =
          this.clearItemArr.prop == "shippingaddress"
            ? "shippingcontact"
            : "shipppingcontact";
        this.formData.shippingcontactphone = val.phonenumber;
        this.formData[name] = val.contact_name;
        let options = [
          {
            value: val.contact_name,
            label: val.contactccname,
          },
        ];
        this.$parent.$set(this.optionList, name, options);
      } else if (
        this.clearItemArr.prop == "billingaddress" ||
        this.clearItemArr.prop == "billing_address"
      ) {
        this.formData.billingcontactphone = val.phonenumber;
        this.formData.billingcontact = val.contact_name;
        let options = [
          {
            value: val.contact_name,
            label: val.contactccname,
          },
        ];
        this.$parent.$set(this.optionList, "billingcontact", options);
      }
      this.showAddressDialog = false;
    },
    /**
     * 更改地址字段弹窗 添加新地址
     */
    addAdderss() {
      CommonObjApi.getPermissionById({
        id: this.formData.accountid || this.formData.account_name,
      }).then((res) => {
        if (res.result && res.data.isAdd == true) {
          this.showAddressDialog = false;
          this.isNew = true;
          this.dialogNewId = "";
          //查询当前客户是否有默认地址
          CommonObjApi.isDefaultAddress({
            customerId: this.formData.accountid || this.formData.account_name,
          }).then((res) => {
            let obj = res.data;
            obj.currentType = this.addressType;
            this.$nextTick(() => {
              this.$refs.creatAddress.add(
                obj,
                "cloudccustomeraddress",
                "objNewBuild",
                "newCreate"
              );
            });
          });
        } else {
          this.$message.warning(this.$t("c657"));
        }
      });
    },
    /**
     * 更改地址字段弹窗 编辑地址
     * @param {Object} val 地址字段信息
     */
    editAddress(val) {
      let id = this.formData.accountid || this.formData.account_name;
      CommonObjApi.getPermissionById({ id: id }).then((res) => {
        if (res.result && res.data.isEdit == true) {
          this.showAddressDialog = false;
          this.isNew = false;
          if (!this.isNew) {
            this.dialogNewId = val.id;
          }
          //查询当前客户是否有默认地址
          CommonObjApi.isDefaultAddress({ customerId: id }).then((res) => {
            let obj = res.data;
            obj.currentType = this.addressType;
            this.$nextTick(() => {
              this.$refs.creatAddress.edit(
                obj,
                "cloudccustomeraddress",
                "objNewBuild",
                "newCreate"
              );
            });
          });
        } else {
          this.$message.warning(this.$t("c657"));
        }
      });
    },
    /**
     * 币种是否可编辑
     */
    async currencyEdit() {
      let obj = {
        layoutId: this.layoutId,
        recordId: this.$route?.params.id,
        relatedlistId: this.relatedlistIds,
      };
      if (
        obj.recordId &&
        obj.recordId !== "" &&
        obj.relatedlistId &&
        obj.relatedlistId !== ""
      ) {
        let result = await projectApi.getDetailRelatedItems(obj);
        if (result.data && result.data.data) {
          this.$emit("changeCurrencyEdit", result.data.data);
        }
      }
    },
    workethoursave(val, item, continueDate) {
      this.$set(this.formData, "working_hours", item);
      this.$set(this.formData, "continue_date", continueDate);
      this.$set(this.formData, "allWorkingHours", val);
    },
    // 地图弹窗中切换谷歌/高德地图
    mapBtnChange(e) {
      this.isMap = e === "gd" ? true : false;
      this.mapFun();
    },
    /**
     * 地址字段 从地图选择
     * @param {Object} item 地址字段信息
     */
    bigMapBtnFn(item) {
      let newAdress = {
        edit: item.edit,
        fieldId: "",
        label: this.$i18n.t("LT"), //地理定位
        name: item.prop,
        required: false,
        type: "S",
        isAdress: true,
        mapFlag: true,
        prop: item.prop,
      };
      this.clearItemArr = newAdress;
      this.bigMapDialog = true;
      if (this.formData[this.clearItemArr.prop + "00"]) {
        if (
          this.formData[this.clearItemArr.prop + "00"] ===
            this.$i18n.t("label.china") ||
          this.formData[this.clearItemArr.prop + "00"] ===
            this.$i18n.t("label.the.peoples.republic.of.china") ||
          this.formData[this.clearItemArr.prop + "00"] === "China"
        ) {
          this.isMap = true;
          this.mapFun();
        } else {
          this.isMap = false;
          this.mapFun();
        }
      } else {
        if (this.userInfo.countryCode === "CN") {
          this.isMap = true;
          this.mapFun();
        } else {
          this.isMap = false;
          this.mapFun();
        }
      }
    },
    /**
     * 地图初始化
     */
    mapFun() {
      if (this.isMap) {
        if (this.operationType === "EDIT") {
          if (
            ((this.formData[this.clearItemArr.prop + "00"] &&
              this.formData[this.clearItemArr.prop + "00"] ===
                this.$i18n.t("label.china")) ||
              this.formData[this.clearItemArr.prop + "00"] ===
                this.$i18n.t("label.the.peoples.republic.of.china") ||
              this.formData[this.clearItemArr.prop + "00"] === "China") &&
            this.formData[this.clearItemArr.prop + "longitude"] &&
            this.formData[this.clearItemArr.prop + "latitude"]
          ) {
            this.clearItemArr.gdPointValue = [
              this.formData[this.clearItemArr.prop + "longitude"],
              this.formData[this.clearItemArr.prop + "latitude"],
            ];
          } else {
            this.clearItemArr.gdPointValue = [];
          }
        }
        this.mapkey = window.Glod["AMAP_KEY"];
      } else {
        if (this.operationType === "EDIT") {
          if (
            this.formData[this.clearItemArr.prop + "00"] &&
            this.formData[this.clearItemArr.prop + "00"] !==
              this.$i18n.t("label.china") &&
            this.formData[this.clearItemArr.prop + "00"] !==
              this.$i18n.t("label.the.peoples.republic.of.china") &&
            this.formData[this.clearItemArr.prop + "00"] !== "China" &&
            this.formData[this.clearItemArr.prop + "longitude"] &&
            this.formData[this.clearItemArr.prop + "latitude"]
          ) {
            this.clearItemArr.ggPointValue = {
              lat: Number(this.formData[this.clearItemArr.prop + "longitude"]),
              lng: Number(this.formData[this.clearItemArr.prop + "latitude"]),
            };
          } else {
            this.clearItemArr.ggPointValue = {};
          }
        }
        this.mapkey = "AIzaSyCyWXGrhpOd1py9eUCpzRAzbDjWtXQNqGU";
      }
      this.$nextTick(() => {
        this.$refs.mapGroup.init();
        this.$refs.mapGroup.searchKey = "";
        this.$refs.mapGroup.searchList = [];
      });
    },
    /**
     * 关闭谷歌/高德地图弹窗
     */
    bigClose() {
      this.bigMapDialog = false;
    },
    /**
     * 选中谷歌/高德地图弹窗中地理位置后,接受并赋值地图信息
     * @param {Object} allAdd
     * @param {Object} obj
     */
    //地图返回信息
    chooseLocation(allAdd, obj) {
      // console.log(this.$refs.AddressFiled[0],'this.$refs.AddressFiled[0]')
      // console.log(this.clearItemArr.prop,'this.clearItemArr.prop')
      // console.log(allAdd,'allAdd')
      // console.log(obj,'obj')
      this.bigMapDialog = false;
      //CN 高德地图 ，海外谷歌
      if (this.isMap) {
        // 当选中的地区为直辖市时，city=province; c981,c980,c979
        let mapCity = allAdd?.addressComponent?.city;
        if (
          !mapCity &&
          (allAdd.addressComponent.province ==
            this.$i18n.t("label.bmap.beijing.city") ||
            allAdd.addressComponent.province == "上海市" ||
            allAdd.addressComponent.province == "重庆市" ||
            allAdd.addressComponent.province == "天津市")
        ) {
          mapCity = allAdd.addressComponent.province;
        }
        /**
         * 国家回显省份
         */
        if (allAdd.addressComponent.country) {
          this.formAttr.forEach((val) => {
            val?.children?.forEach((child) => {
              if (child.prop === this.clearItemArr.prop + "00") {
                if ((child, allAdd.addressComponent.country === "中国")) {
                  this.$refs?.AddressFiled[0]?.addressChange({
                    childrenVal: null,
                    arg: null,
                  });
                }

                this.$emit(
                  "control",
                  allAdd.addressComponent.country,
                  child.fieldId,
                  this.formData
                );
              }

              // 传省->获取城市
              if (child.prop === this.clearItemArr.prop + "01") {
                this.$emit(
                  "getCity",
                  allAdd.addressComponent.province,
                  child.fieldId,
                  this.formData
                );
              }
              // 传城市->获取县城
              if (child.prop === this.clearItemArr.prop + "02") {
                this.$emit(
                  "getCounty",
                  allAdd.addressComponent.city,
                  child.fieldId,
                  this.formData
                );
              }
            });
          });
        }

        this.clearItemArr.gdPointValue = obj;
        this.formData[this.clearItemArr.prop + "00"] =
          allAdd.addressComponent.country;
        this.formData[this.clearItemArr.prop + "01"] =
          allAdd.addressComponent.province;
        this.formData[this.clearItemArr.prop + "02"] = mapCity;
        this.formData[this.clearItemArr.prop + "03"] =
          allAdd.addressComponent.district;
        this.formData[this.clearItemArr.prop + "04"] = allAdd.formattedAddress;
        this.formData[this.clearItemArr.prop + "longitude"] = obj[0];
        this.formData[this.clearItemArr.prop + "latitude"] = obj[1];

        // 详细地址改成了两个字段 给这两个字段赋值 （只是前端改了 后端没有加字段 前端将两个字段用#拼成一个字段传给了后端）
        this.$refs.AddressFiled[0].detailAdd = this.formData[this.clearItemArr.prop + "04"].split(' - ')[0]
        // this.$refs.AddressFiled[0].houseNum = this.formData[this.clearItemArr.prop + "04"].split(' - ')[1]
        let add = this.formData[this.clearItemArr.prop + "04"]
        this.$refs.AddressFiled[0].houseNum = add.indexOf(" - ") == -1 ? "" : add.substr(add.indexOf(" - ")+3)
        // 选择地图之后门牌号可填写
        this.$refs.AddressFiled[0].isHouseNumDisAble = false
        // 从地图选择后地址信息就置为只读
        this.$refs.AddressFiled[0].item.children.forEach(children => {
          children.edit = false
        })
      } else {
        this.clearItemArr.ggPointValue = allAdd.geometry.location;
        let allAddLength = "";
        allAdd.address_components.forEach((val, index) => {
          //如果是美国/加拿大/澳大利亚/墨西哥/英国/新加坡/法国/德国/的话找对应下标赋值
          if (
            val.short_name === "US" ||
            val.short_name === "CA" ||
            val.short_name === "AU" ||
            val.short_name === "MX" ||
            val.short_name === "GB" ||
            val.short_name === "SG" ||
            val.short_name === "FR" ||
            val.short_name === "DE"
          ) {
            allAddLength = index;
          }
        });
        this.formAttr.forEach((val) => {
          if (val.prop === this.clearItemArr.prop + "00") {
            this.$emit(
              "control",
              allAdd.address_components[allAddLength].long_name,
              val.fieldId,
              this.formData
            );
          }
        });
        this.formData[this.clearItemArr.prop + "00"] =
          allAdd.address_components[allAddLength].long_name;
        this.formData[this.clearItemArr.prop + "01"] =
          allAdd.address_components[allAddLength - 1].long_name;
        this.formData[this.clearItemArr.prop + "02"] =
          allAdd.address_components[allAddLength - 2].long_name;
        this.formData[this.clearItemArr.prop + "04"] = allAdd.formatted_address;
        this.formData[this.clearItemArr.prop + "longitude"] =
          allAdd.geometry.location.lat;
        this.formData[this.clearItemArr.prop + "latitude"] =
          allAdd.geometry.location.lng;
        
        // 详细地址改成了两个字段 给这两个字段赋值 （只是前端改了 后端没有加字段 前端将两个字段用#拼成一个字段传给了后端） 
        this.$refs.AddressFiled[0].detailAdd = this.formData[this.clearItemArr.prop + "04"].split(' - ')[0]
        // this.$refs.AddressFiled[0].houseNum = this.formData[this.clearItemArr.prop + "04"].split(' - ')[1]
        let add = this.formData[this.clearItemArr.prop + "04"]
        this.$refs.AddressFiled[0].houseNum = add.indexOf(" - ") == -1 ? "" : add.substr(add.indexOf(" - ")+3)
        // 选择地图之后门牌号可填写
        this.$refs.AddressFiled[0].isHouseNumDisAble = false
        // 从地图选择后地址信息就置为只读
        this.$refs.AddressFiled[0].item.children.forEach(children => {
          children.edit = false
        })
      }
      // console.log(this.clearItemArr,'this.clearItemArr')
    },
    /**
     * 清空地图信息
     */
    clearLocation() {
      this.bigMapDialog = false;
      this.clearItemArr.gdPointValue = [];
      this.clearItemArr.ggPointValue = {};
      this.formData[this.clearItemArr.prop + "00"] = "";
      this.formData[this.clearItemArr.prop + "01"] = "";
      this.formData[this.clearItemArr.prop + "02"] = "";
      this.formData[this.clearItemArr.prop + "04"] = "";
    },
    /**
     * 点击知识文章下的内部链接
     * @param {Object} item 字段信息
     */
    LinkarticleClick(item) {
      const arr = [];
      this.formAttr.forEach((item) => {
        if (item.type === "kindeditor") {
          arr.push(item.apiname);
        }
      });
      const editIndex = arr.indexOf(item.apiname);
      this.$refs.linkarticle[editIndex].statechange();
      this.$refs.linkarticle[editIndex].queryLanguage();
    },
    /**
     * 处理长文本字符限制
     * @param {Object} item 字段信息
     */
    handleStr(item) {
      let maxlength = 32000;
      if (item.fieldLength) {
        maxlength = Number(item.fieldLength);
      }
      if (item.length) {
        maxlength = item.length;
      }
      return maxlength;
    },
    /**
     * 处理帮助文本特殊字符回车显示#enter类似问题
     * @param {String} helpText 文本信息
     */
    helpText(helpText) {
      if (helpText === undefined || helpText === null) {
        return helpText;
      } else {
        return helpText
          .replace(/#enter/g, "<br />")
          .replace(/#quoteDouble/g, '"')
          .replace(/#quoteSingle/g, "'")
          .replace(/#quote/g, "'");
      }
    },
    /**
     * 获取当前时间并返回
     * @param {Number} index 用于区分返回时间的格式,index为1时返回年-月-日,index为2时返回年-月-日 时:分:秒
     */
    formatDateTime(index) {
      var y = new Date().getFullYear();
      var m = new Date().getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = new Date().getDate();
      d = d < 10 ? "0" + d : d;
      let hh =
        (new Date().getHours() < 10
          ? "0" + new Date().getHours()
          : new Date().getHours()) + ":";
      let mm =
        (new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes()) + ":";
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      if (index == "2") {
        return y + "-" + m + "-" + d + " " + hh + mm + ss;
      }
      return y + "-" + m + "-" + d;
    },
    /**
     * 潜在客户打分字段 鼠标移入/移出星星事件 根据传入参数设置当前评分字段每个星星的样式
     * @param {String} nub 当前鼠标悬浮星星的下标/鼠标移出标识
     * @param {Object} item 当前星星所在字段信息
     */
    setStar(nub, item) {
      this.stars = document.querySelectorAll(`.${item.apiname} span`);

      if (nub === "active") {
        nub = Number(item.value);
      }
      let name = "";
      name = "show2";
      for (var i = 0; i < this.stars.length; i++) {
        // 区分鼠标悬浮和鼠标离开
        this.stars[i].className = i <= nub ? name : "";
      }
    },
    /**
     * 设置评分字段的值
     * @param {Object} item 当前字段信息
     * @param {Number} idx 当前评分字段的值
     */
    setClick(item, idx) {
      item.value = idx;
      this.formData[item.apiname] = idx;
    },
    // 事件任务类型相关项选择
    checkItem(index, item) {
      let option = {};
      option.fieldId = item.fieldId;
      option.fieldType = item.fieldType;
      if (index == 0) {
        this.eventIndex = index;
        if (this.eventValueA) {
          option.relevantPrefix = this.eventValueA;
          item.optionItem.map((item) => {
            if (item.prefix == this.eventValueA) {
              option.relevantObjId = item.id;
              option.relevantObjApi = item.schemetableName;
            }
          });
          this.eventSearch(option);
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_selectname")
          );
        }
      } else if (index == 1) {
        this.eventIndex = index;
        if (this.eventValueB) {
          option.relevantPrefix = this.eventValueB;
          item.optionItem.map((item) => {
            if (item.prefix == this.eventValueB) {
              option.relevantObjId = item.id;
              option.relevantObjApi = item.schemetableName;
            }
          });
          this.eventSearch(option);
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_select_first")
          );
        }
      }
    },
    eventSearch(item) {
      this.fieldId = item.fieldId;
      this.relevantObjId = item.relevantObjId;
      this.relevantObjApi = item.relevantObjApi;
      this.relevantPrefix = item.relevantPrefix;
      this.showSearchTable = true;
      this.checked = item.fieldType === "MR" ? true : false;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /**
     * 获取任务/事件下拉框默认选项
     */
    getDefaultOption() {
      if (
        this.specialObjectapi === "Event" ||
        this.specialObjectapi === "Task"
      ) {
        CommonObjApi.getDialigItem({ type: this.objId }).then((res) => {
          this.defaultPrefix = {
            relate: res.data.relateobjList && res.data.relateobjList[0].prefix,
            name: res.data.whoobjInfoList && res.data.whoobjInfoList[0].prefix,
          };
          this.formAttr.forEach((item) => {
            if (item.prop === "whoid") {
              if (item.value) {
                this.eventValueA = item.child.whoobj; // 名称
                this.eventCheckedObjA = {
                  id: item.value,
                  name: item.child.whoname,
                };
              } else {
                this.eventValueA = this.defaultPrefix.name; //  名称
              }
            }
            if (item.prop === "relateid") {
              if (item.value) {
                this.eventValueB = item.child.relateobj; // 相关项
                this.eventCheckedObjB = {
                  id: item.value,
                  name: item.child.relatename,
                };
              } else {
                this.eventValueB = this.defaultPrefix.relate; // 相关项
              }
            }
          });
        });
      }
    },
    eventChangeA(e) {
      this.eventValueA = e;
      this.eventCheckedObjA = {};
      this.formData.whoid = "";
    },
    eventChangeB(e) {
      this.eventValueB = e;
      this.eventCheckedObjB = {};
      this.formData.relateid = "";
    },
    /**
     * 业务机会对象 页面布局中有 阶段 字段时,获取 阶段-可能性 对应关系
     */
    getKNXSelectValue() {
      CommonObjApi.getSelectValue({ fieldId: "ffe201100003943eFUc8" }).then(
        (res) => {
          if (res.result && res.returnCode === "1") {
            let options = [];
            res.data.forEach((item) => {
              options.push({
                val: item.codekey,
                key: item.codevalue,
                knx: Number(item.knx),
                forecasttype: item.forecasttype,
              });
            });
            this.possibilityList = options;

            // 新建时业务机会可能性、预测类别随阶段变化
            if (
              this.operationType === "NEW" &&
              this.prefix === "002" &&
              this.formData["jieduan"] !== undefined
            ) {
              let knx = this.possibilityList.find((item) => {
                return item.val === this.formData["jieduan"];
              });
              if (knx !== undefined) {
                if ("knx" in this.formData) {
                  this.formData["knx"] = knx.knx;
                }
                if ("forecasttype" in this.formData) {
                  this.formData["forecasttype"] = knx.forecasttype;
                }
              }
            }
          } else {
            this.$message.error(res.returnInfo);
          }
        }
      );
    },
    /**
     * 关联列表点击新建-根据任务 问题 子任务 带值
     */
    getProjectByTask() {
      let params = {
        objectApi: $cookies.get("parentObjectApi"),
        recordId: this.relativeRecordId,
      };
      projectApi.getProjectByTask(params).then((res) => {
        // 项目名称代值
        if (res.data && res.data.length > 0) {
          this.$set(this.formData, "project_name", res.data[0].projectid);
          let options = [
            {
              value: res.data[0].projectid,
              label: res.data[0].projectname,
            },
          ];
          this.$parent.$set(this.optionList, "project_name", options);
          // 任务/问题代值
          this.taskOrProblemVal = res.data[0].id;
          this.findMemberByProject(res.data[0].projectid);
        }
      });
    },
    /**
     * 根据项目获取当前登录人在该项目下的成员情况
     */
    findMemberByProject(projectId) {
      let params = {
        projectId: projectId,
      };
      projectApi.findMemberByProject(params).then((res) => {
        // 项目成员名称代值
        if (res.data && res.data.length > 0) {
          this.$set(this.formData, "member", res.data[0].id);
          let options = [
            {
              value: res.data[0].id,
              label: res.data[0].name,
            },
          ];
          this.$parent.$set(this.optionList, "member", options);
        } else {
          this.$set(this.formData, "member", "");
          let options = [];
          this.$parent.$set(this.optionList, "member", options);
        }
      });
    },
    /**
     * 表单数据双向绑定 根据当前表单字段信息,获取当前表单数据,以及设置表单验证规则
     * @param {Boolean} flag:是否是created生命周期中调用
     */
    filterFormData(flag) {
      let dependContainerId = [];
      if (flag && Array.isArray(this.dependContainer)) {
        dependContainerId = this.dependContainer.map((item) => item.id);
      }
      this.rules = {};
      this.formData = {};
      this.formAttr &&
        this.formAttr.forEach((v) => {
          if (this.objectApi == "WorkRule") {
            // 工作规则-资源属性字段
            if (v.apiname == "resourcespropertyfield") {
              v.type = "select";
              let options = [];
              // 获取选项
              CommonObjApi.getResourceAttributeFields().then((res) => {
                res.data.forEach((item) => {
                  // 留着选中后匹配类型用
                  this.resourceAttributeFields.push(...item.fieldList);
                  // 组合数据
                  let obj = {};
                  obj.label = item.objName;
                  obj.options = item.fieldList.map((items) => {
                    return {
                      key: items.labelName,
                      val: items.id,
                    };
                  });
                  // 给资源属性字段赋值选项-且分组
                  options.push(obj);
                });
                this.$parent.$set(this.optionList, v.prop, options);
                if (v.value) {
                  // 选的字段类型
                  let curValue = this.resourceAttributeFields.find((item) => {
                    return item.id === v.value;
                  });
                  // 加载运算符的选项
                  let conditions =
                    operator.TYPE[
                      curValue.schemefieldType
                    ].getScreeningOpeTypes();
                  let options2 = conditions.map((ele) => {
                    return {
                      key: ele.name,
                      val: ele.id,
                    };
                  });
                  this.$parent.$set(this.optionList, "fieldoperator", options2);
                  this.formAttr.forEach((item2) => {
                    if (item2.apiname == "resourcespropertyvalue") {
                      // 字段类型对应不同的样式
                      item2.type = INPUTTYPE[curValue.schemefieldType];
                      // 资源属性值
                      if (
                        curValue.schemefieldType == "L" ||
                        curValue.schemefieldType == "Q"
                      ) {
                        CommonObjApi.getSelectValue({ fieldId: v.value }).then(
                          (res) => {
                            if (res.result && res.returnCode === "1") {
                              let options = [];
                              res.data.forEach((item) => {
                                options.push({
                                  val: item.codekey,
                                  key: item.codevalue,
                                });
                              });
                              this.$set(this.optionList, item2.prop, options);
                            } else {
                              this.$message.error(res.returnInfo);
                            }
                          }
                        );
                      }
                      if (curValue.schemefieldType == "P") {
                        let decimal =
                          curValue.decimalPlaces !== undefined &&
                          curValue.decimalPlaces !== null
                            ? 1 / Math.pow(10, Number(curValue.decimalPlaces))
                            : 0;
                        item2.valueInterval = {
                          min:
                            0 -
                            Math.pow(
                              10,
                              curValue.schemefieldLength -
                                curValue.decimalPlaces -
                                1
                            ) +
                            decimal,
                          max:
                            Math.pow(
                              10,
                              curValue.schemefieldLength -
                                curValue.decimalPlaces -
                                1
                            ) - decimal,
                        };
                      }
                    }
                  });
                }
              });
            } else if (v.apiname == "resourcespropertyvalue") {
              // 资源属性值为复选框类型字段转换一下数据类型
              if (
                v.value === "true" ||
                v.value === true ||
                v.value === "false" ||
                v.value === false
              ) {
                if (v.value === "true" || v.value === true) {
                  v.value = true;
                } else if (v.value === "false" || v.value === false) {
                  v.value = false;
                }
              }
            }
            // 字段运算符
            if (v.apiname == "fieldoperator") {
              v.type = "select";
            }
          }
          // 服务目标，自定义目标类型 ----开始
          if (
            this.objectApi == "ServiceGoal" &&
            v.apiname == "customobjectivetype"
          ) {
            // 注：接口返回的value就是中文
            if ("资源资格" == v.value) {
              // 资格字段
              this.formAttr.forEach((item) => {
                if (item.apiname == "qualificationfield") {
                  item.type = "select";
                  this.resourceAttributeFields = [];
                  let options = [];
                  CommonObjApi.getQualificationFields().then((res) => {
                    res.data.forEach((item) => {
                      // 留着选中后匹配类型用
                      this.resourceAttributeFields.push(...item.fieldList);
                      // 组合数据
                      let obj = {};
                      obj.label = item.objName;
                      obj.options = item.fieldList.map((items) => {
                        return {
                          key: items.labelName,
                          val: items.id,
                        };
                      });
                      // 给资源属性字段赋值选项-且分组
                      options.push(obj);
                    });
                    this.$parent.$set(this.optionList, item.prop, options);
                  });
                }
              });
            }
          }
          // 百分比、数字、币种字段若为空，设置成undefined，不让其显示成最小值
          if (
            (v.fieldType === "P" ||
              v.fieldType === "N" ||
              v.fieldType === "c") &&
            (v.value === "" || v.value === null)
          ) {
            this.$set(this.formData, v.prop, undefined);
          } else if (v.prop === "recordtype") {
            this.$set(this.formData, v.prop, v.id);
          } else if (v.type == "file" && v.data && v.value == null) {
            let val = "";
            v.data.forEach((res) => {
              val += res.id;
            });
            this.$set(this.formData, v.prop, val);
          } else if (v.fieldType == "AD") {
            //当类型为地址字段时，设置其子级数据
            v.children.forEach((res) => {
              this.$set(this.formData, res.prop, res.value);
            });
          } else if (v.fieldType == "B") {
            // 复选框类型
            if (v.value === "true") {
              this.$set(this.formData, v.prop, true);
            } else {
              this.$set(this.formData, v.prop, v.value);
            }
          } else {
            // 当v.value是数组时，去除v.value中的null和undefined,防止el-select组件 v-model绑定一个对象值
            if (Array.isArray(v.value)) {
              v.value = v.value.filter(
                (item) => item !== null && item !== undefined
              );
            }
            this.$set(this.formData, v.prop, v.value);
          }
          // 如果有验证规则
          // 字段必填且不只读时加必填规则
          //地址字段添加验证规则
          if (v.children) {
            v.children.forEach((vc) => {
              let name = vc.prop;
              vc.rules != undefined &&
                vc.rules.forEach((val) => {
                  // 依赖字段如果是只读，不能加必填规则
                  if (!val.required || v.DpendFieldEdit || !v.isDpendFieldid) {
                    if (
                      !(dependContainerId.includes(v.fieldId) && val.require)
                    ) {
                      if (this.rules[name]) {
                        this.rules[name].push(val);
                      } else {
                        let arr = [];
                        arr.push(val);
                        this.$set(this.rules, name, arr);
                      }
                    }
                  }
                });
            });
          }
          if (v.rules && v.rules.length && v.edit) {
            let name = v.prop;

            v.rules.forEach((val) => {
              // 依赖字段如果是只读，不能加必填规则
              if (!val.required || v.DpendFieldEdit || !v.isDpendFieldid) {
                if (!(dependContainerId.includes(v.fieldId) && val.require)) {
                  if (this.rules[name]) {
                    this.rules[name].push(val);
                  } else {
                    let arr = [];
                    arr.push(val);
                    this.$set(this.rules, name, arr);
                  }
                }
              }
            });

            //启用外部用户判断用户值是否是邮箱格式
            if (this.contactes === "contactsNew" && v.apiname === "loginname") {
              this.rules[name].push({ validator: this.isEmail });
            }

            // 邮件、手机添加校验规则
            if (v.fieldType === "E") {
              this.rules[name].push({ validator: this.isEmail });
            } else if (v.fieldType === "P") {
              this.rules[name].push({ validator: this.isNumber });
            } else if (v.fieldType === "c") {
              let isNumberc = (rule, value, callback) => {
                if (value !== undefined && isNaN(value)) {
                  // 数字输入格式错误，请检查输入数字
                  return callback(
                    new Error(
                      this.$i18n.t("front-view-module-v12-verify-number")
                    )
                  );
                } else {
                  callback();
                }
              };
              this.rules[name].push({ validator: isNumberc });
            } else if (v.apiname == "actual_working_hours") {
              //实际工作清单报工工时

              let querrnumrules = (rule, value, callback) => {
                if (value) {
                  if (!isNaN(value)) {
                    let length = v.fieldLength || v.length;
                    let decimal =
                      v.decimalPlaces !== undefined && v.decimalPlaces !== null
                        ? 1 / Math.pow(10, Number(v.decimalPlaces))
                        : 0;
                    let min =
                      0 - Math.pow(10, length - v.decimalPlaces - 1) + decimal;
                    let max =
                      Math.pow(10, length - v.decimalPlaces - 1) - decimal;

                    if (Number(value) >= min && Number(value) <= max) {
                      callback();
                    } else {
                      return callback(
                        new Error(
                          this.$i18n.t("front-view-module-v12-verify-number")
                        )
                      );
                    }
                  } else {
                    return callback(
                      new Error(
                        this.$i18n.t("front-view-module-v12-verify-number")
                      )
                    );
                  }
                } else {
                  callback();
                }
              };
              this.rules[name].push({ validator: querrnumrules });
            }

            // twitter,linkedin,facebook有验证规则
            if (v.apiname === "twitter") {
              this.rules[name].push({ validator: this.twitter });
            } else if (v.apiname === "linkedin") {
              this.rules[name].push({ validator: this.linkedin });
            } else if (v.apiname === "facebook") {
              this.rules[name].push({ validator: this.facebook });
            }
          } else if (v.fieldType === "E") {
            let arr = [{ validator: this.isEmail }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.fieldType === "P") {
            let arr = [{ validator: this.isNumber }];
            this.$set(this.rules, v.prop, arr);
          } else if (
            this.contactes === "contactsNew" &&
            v.apiname === "loginname"
          ) {
            this.$set(this.rules, v.prop, arr);
          } else if (v.apiname === "taskOrProblem") {
            // 实际工作清单-任务/问题字段添加必填校验
            let arr = [
              {
                required: true,
                message: this.$i18n.t(
                  "label.projectManagement.please.select.task.or.question"
                ),
                trigger: "blur",
              }, //"请选择任务/问题"
            ];
            this.$set(this.rules, v.prop, arr);
          } else if (v.fieldType === "N" || v.fieldType === "c") {
            let querrnumrules = (rule, value, callback) => {
              if (value) {
                if (!isNaN(value)) {
                  let length = v.fieldLength || v.length;
                  let decimal =
                    v.decimalPlaces !== undefined && v.decimalPlaces !== null
                      ? 1 / Math.pow(10, Number(v.decimalPlaces))
                      : 0;
                  let min =
                    0 - Math.pow(10, length - v.decimalPlaces - 1) + decimal;
                  let max =
                    Math.pow(10, length - v.decimalPlaces - 1) - decimal;

                  if (Number(value) >= min && Number(value) <= max) {
                    callback();
                  } else {
                    return callback(
                      new Error(
                        this.$i18n.t("front-view-module-v12-verify-number")
                      )
                    );
                  }
                } else {
                  return callback(
                    new Error(
                      this.$i18n.t("front-view-module-v12-verify-number")
                    )
                  );
                }
              } else {
                callback();
              }
            };
            let numberquery = [{ validator: querrnumrules }];
            this.$set(this.rules, v.prop, numberquery);
          }

          // 必填项不为空字符串校验 v.required ,自动编号字段除外、依赖字段除外
          if (
            v.required &&
            v.fieldType !== "V" &&
            v.apiname !== "principal" &&
            !dependContainerId.includes(v.fieldId)
          ) {
            if (this.rules[v.prop] !== undefined) {
              this.rules[v.prop].push({ validator: this.isEmpty });
            }
          }
        });

      // 获取任务事件下拉框默认选项
      this.formAttr && this.getDefaultOption();
      // 详情-关联列表任务、问题、子任务点击实际工作清单新建带值
      if (this.prefix === "p10") {
        this.formAttr && this.getProjectByTask();
        this.formAttr &&
          this.formData.project_name &&
          this.findMemberByProject(this.formData.project_name);
      }
      // 事件类型 计算时间差
      if (
        this.specialObjectapi === "Event" &&
        this.formData.endtime &&
        this.formData.begintime
      ) {
        this.timeDiff = this.timeFn(
          this.formData.begintime,
          this.formData.endtime
        );
      }

      // 组件内部处理完formData后表单才显示，父组件才能通过$refs获取到表单组件
      // 传入第一次created调用标识，用于编辑情况下第一次不走查找带值规则的判断依据
      this.$emit("ruleProcessing", flag);
    },
    /**
     * 重置表单验证规则
     * @param {object} field:清空或者重置指定字段的字段必填规则（字段依赖使用）
     */
    resetRules(field) {
      if (field) {
        // 依赖字段，当规则数为0的时候，更新规则
        if (field.isDpendFieldid) {
          // 判断是否有必填规则
          let includItme = false;
          if (this.rules[field.prop] && this.rules[field.prop].length > 0) {
            includItme = this.rules[field.prop].find((item) => item.required);
          }
          if (field.DpendFieldEdit) {
            if (!includItme && field.rules && Array.isArray(field.rules)) {
              // 添加必填规则
              let obj = {};
              obj[field.prop] = JSON.parse(JSON.stringify(field.rules));
              this.rules = { ...this.rules, ...obj };
            }
          } else {
            // 取消必填规则
            if (includItme && field.rules && Array.isArray(field.rules)) {
              // 删除原来依赖字段的所有规则
              delete this.rules[field.prop];
              // 添加必填规则
              let obj = {};
              let rulesAry = field.rules.filter((item) => {
                if (!item.required) {
                  return item;
                }
              });
              if (rulesAry.length > 0) {
                obj[field.prop] = rulesAry;
              }
              this.rules = { ...this.rules, ...obj };
            }
          }
        }
      } else {
        this.rules = {};
        let dependContainerId = [];
        if (Array.isArray(this.dependContainer)) {
          dependContainerId = this.dependContainer.map((item) => item.id);
        }
        this.formAttr &&
          this.formAttr.forEach((v) => {
            // 如果有验证规则
            //地址字段添加验证规则
            if (v.children) {
              v.children.forEach((vc) => {
                let name = vc.prop;
                vc.rules != undefined &&
                  vc.rules.forEach((val) => {
                    // 依赖字段如果是只读，不能加必填规则
                    if (
                      !val.required ||
                      v.DpendFieldEdit ||
                      !v.isDpendFieldid
                    ) {
                      if (this.rules[name]) {
                        this.rules[name].push(val);
                      } else {
                        let arr = [];
                        arr.push(val);
                        this.$set(this.rules, name, arr);
                      }
                    }
                  });
              });
            }
            if (v.rules && v.rules.length && v.edit) {
              let name = v.prop;
              v.rules.forEach((val) => {
                // 依赖字段如果是只读，不能加必填规则
                if (!val.required || v.DpendFieldEdit || !v.isDpendFieldid) {
                  if (this.rules[name]) {
                    this.rules[name].push(val);
                  } else {
                    let arr = [];
                    arr.push(val);
                    this.$set(this.rules, name, arr);
                  }
                }
              });

              //启用外部用户判断用户值是否是邮箱格式
              if (
                this.contactes === "contactsNew" &&
                v.apiname === "loginname"
              ) {
                this.rules[name].push({ validator: this.isEmail });
              }

              if (v.fieldType === "E") {
                this.rules[name].push({ validator: this.isEmail });
              } else if (v.fieldType === "P") {
                this.rules[name].push({ validator: this.isNumber });
              } else if (v.fieldType === "c") {
                let isNumberc = (rule, value, callback) => {
                  if (value !== undefined && isNaN(value)) {
                    // 数字输入格式错误，请检查输入数字
                    return callback(
                      new Error(
                        this.$i18n.t("front-view-module-v12-verify-number")
                      )
                    );
                  } else {
                    callback();
                  }
                };
                this.rules[name].push({ validator: isNumberc });
              } else if (v.apiname == "actual_working_hours") {
                //实际工作清单报工工时
                let querrnumrules = (rule, value, callback) => {
                  if (value) {
                    if (!isNaN(value)) {
                      let length = v.fieldLength || v.length;
                      let decimal =
                        v.decimalPlaces !== undefined &&
                        v.decimalPlaces !== null
                          ? 1 / Math.pow(10, Number(v.decimalPlaces))
                          : 0;
                      let min =
                        0 -
                        Math.pow(10, length - v.decimalPlaces - 1) +
                        decimal;
                      let max =
                        Math.pow(10, length - v.decimalPlaces - 1) - decimal;

                      if (Number(value) >= min && Number(value) <= max) {
                        callback();
                      } else {
                        return callback(
                          new Error(
                            this.$i18n.t("front-view-module-v12-verify-number")
                          )
                        );
                      }
                    } else {
                      return callback(
                        new Error(
                          this.$i18n.t("front-view-module-v12-verify-number")
                        )
                      );
                    }
                  } else {
                    callback();
                  }
                };
                this.rules[name].push({ validator: querrnumrules });
              }
            } else if (v.fieldType === "E") {
              let arr = [{ validator: this.isEmail }];
              this.$set(this.rules, v.prop, arr);
            } else if (v.fieldType === "P") {
              let arr = [{ validator: this.isNumber }];
              this.$set(this.rules, v.prop, arr);
            } else if (v.apiname === "twitter") {
              // twitter,linkedin,facebook无验证规则
              let arr = [{ validator: this.twitter }];
              this.$set(this.rules, v.prop, arr);
            } else if (v.apiname === "linkedin") {
              let arr = [{ validator: this.linkedin }];
              this.$set(this.rules, v.prop, arr);
            } else if (v.apiname === "facebook") {
              let arr = [{ validator: this.facebook }];
              this.$set(this.rules, v.prop, arr);
            } else if (v.apiname === "taskOrProblem") {
              // 实际工作清单-任务/问题字段添加必填校验
              let arr = [
                {
                  required: true,
                  message: this.$i18n.t(
                    "label.projectManagement.please.select.task.or.question"
                  ),
                  trigger: "blur",
                }, //"请选择任务/问题"
              ];
              this.$set(this.rules, v.prop, arr);
            } else if (v.fieldType === "N" || v.fieldType === "c") {
              let querrnumrules = (rule, value, callback) => {
                if (value) {
                  if (!isNaN(value)) {
                    let length = v.fieldLength || v.length;
                    let decimal =
                      v.decimalPlaces !== undefined && v.decimalPlaces !== null
                        ? 1 / Math.pow(10, Number(v.decimalPlaces))
                        : 0;
                    let min =
                      0 - Math.pow(10, length - v.decimalPlaces - 1) + decimal;
                    let max =
                      Math.pow(10, length - v.decimalPlaces - 1) - decimal;

                    if (Number(value) >= min && Number(value) <= max) {
                      callback();
                    } else {
                      return callback(
                        new Error(
                          this.$i18n.t("front-view-module-v12-verify-number")
                        )
                      );
                    }
                  } else {
                    return callback(
                      new Error(
                        this.$i18n.t("front-view-module-v12-verify-number")
                      )
                    );
                  }
                } else {
                  callback();
                }
              };
              let numberquery = [{ validator: querrnumrules }];
              this.$set(this.rules, v.prop, numberquery);
            }

            // 必填项不为空字符串校验 v.required,自动编号字段除外
            if (
              v.required &&
              v.fieldType !== "V" &&
              v.apiname !== "principal" &&
              !dependContainerId.includes(v.fieldId)
            ) {
              if (this.rules[v.prop] !== undefined) {
                this.rules[v.prop].push({ validator: this.isEmpty });
              }
            }
          });
      }
    },
    /**
     * 文件类型字段 选择文件
     * @param {Object} item 字段信息
     * @param {String} addFieldType 上传类型
     */
    addFile(item, addFieldType) {
      this.$emit("addFile", item, addFieldType);
      this.addFieldType = "";
    },
    /**
     * 文件类型字段 删除文件
     * @param {Object} item 字段信息
     */
    removeFile(item, index) {
      this.$emit("removeFile", item, index);
    },
    /**
     * 文件下载
     * @param {Object} file 要下载的文件信息
     */
    downLoadFile(file) {
      let baseURL = this.$baseConfig.baseURL;
      let token = this.$CCDK.CCToken.getToken();
      var link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${
        file.file_info_id || file.id
      }`;
      link.click();
    },
    /**
     * 获取文件大小
     */
    queryLimit() {
      CommonObjApi.queryLimit({ type: "file" }).then((res) => {
        this.fileSize = Number(res.data.slice(0, res.data.length - 2)) * 1024;
      });
    },
    /**
     * 文件上传成功回调事件
     * @param {String} prop 字段属性
     * @param {String} fileId 字段id
     * @param {isPng} isPng 是否是png类型的文件
     */

    /**
     * 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
     */
    resetFiled() {
      this.$refs.elForm && this.$refs.elForm.resetFields();
    },
    /**
     * 查找/查找多选/主详字段精准搜索
     * @param {Object} item 字段信息
     */
    remoteSearch(item) {
      // 记录当前查找的查找对象，快速新建的时候使用
      this.relevantObj = item;
      this.taskOrquestion = this.formData.taskOrProblem
        ? this.formData.taskOrProblem
        : "";
      this.remoType = item.fieldType;
      //在项目管理系统下对负责人所属项目的判断
      if (
        item.apiname == "principal" ||
        item.apiname == "member" ||
        (window.location.href.substring(window.location.href.length - 3) ==
          "p04" &&
          (item.apiname == "milestone" ||
            item.apiname == "affected_milestones"))
      ) {
        if (
          this.formData.project_name == "" ||
          this.formData.their_project == "" ||
          this.formData.their_project_name == ""
        ) {
          this.$message.error(
            this.$i18n.t("label.projectManagement.selectitem")
          ); //请选择项目
          return;
        }
      }
      if (item.prop == "project_name") {
        this.projectNameFlag = "project_name";
      }
      this.milestone = item.apiname;
      this.projectId =
        this.formData.their_project ||
        this.formData.project_name ||
        this.formData.their_project_name;
      this.eventIndex = -1;
      this.relevantObjApi = "";
      if (this.objectApi == "WorkRule") {
        // 工作规则
        this.fieldId = this.formData.resourcespropertyfield;
      } else {
        this.fieldId = item.fieldId;
      }
      this.relevantObjId = item.objId || item.lookupObjid;
      this.relevantPrefix = item.prefix || item.lookupObj;
      this.checked = item.fieldType === "MR" ? true : false;
      this.$emit("notZhucongfield");
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.optionCheckedArr = [];
        if (this.optionList[item.apiname]) {
          let selectoption = [...this.optionList[item.apiname]].filter((res) =>
            [...this.formData[item.apiname]].some((item) => res.value == item)
          );
          selectoption.map((item, index) => {
            this.optionCheckedArr.push({ id: item.value, name: item.label });
          });
        }
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        if (item.type === "remote-select") {
          this.$refs.searchTable.init(this.searchValueText);
          this.$nextTick(() => {
            this.searchValueText = "";
          });
        } else {
          this.$refs.searchTable.init();
        }
      });
    },
    /**
     * 改变查找/查找多选的值
     * @param {Array/Object} row 查找/查找多选/主详字段弹窗选中的数据
     */
    changeSelect(row) {
      if (
        this.prefix === "p10" &&
        row.field.schemetableId == "cloudcc_project"
      ) {
        this.formAttr && this.findMemberByProject(row.data.id);
      }
      //获取所有选中数据的id（查找多选回显选中数据需要）
      if (row instanceof Array) {
        let rowIds = [];
        row.forEach((val) => {
          rowIds.push({ id: val.id });
        });
        this.optionCheckedArr = rowIds;
      }
      if (this.eventIndex == 0) {
        if (row.length === 0) {
          this.eventCheckedObjA = {};
          this.formData.whoobj = "";
        } else {
          this.eventCheckedObjA = row.data;
          this.formData.whoobj = this.eventValueA;
        }
        this.eventIndex = -1;
      } else if (this.eventIndex == 1) {
        if (row.length === 0) {
          this.eventCheckedObjB = {};
          this.formData.relateobj = "";
        } else {
          this.eventCheckedObjB = row.data;
          this.formData.relateobj = this.eventValueB;
        }
        this.eventIndex = -1;
      }
      this.showSearchTable = false;
      this.$parent.isZhucongfield = false;
      //判断从项目名称打开搜索，且选择不同项目名称进行清空数据
      if (
        this.projectNameFlag == "project_name" &&
        row.data.id != this.formData.project_name
      ) {
        this.porpValueChange();
      }
      this.$emit("changeSelect", row, this.fieldId, this.formData);
      this.projectNameFlag = "";
    },
    /**
     * 设置查找筛选条件
     * @param {Array} filterFieldIds 筛选条件中包含的字段信息
     */
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      values.push(this.id);
      for (let reltaion in filterFieldIds) {
        let attr = this.formAttr.find((item) => {
          return item.fieldId === filterFieldIds[reltaion].fieldid;
        });
        if (
          attr !== undefined &&
          this.formData[attr.prop] !== null &&
          Array.isArray(this.formData[attr.prop])
        ) {
          values.push(this.formData[attr.prop].join(";"));
        } else if (attr !== undefined && this.formData[attr.prop] !== null) {
          values.push(this.formData[attr.prop]);
        }
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    /**
     * 关闭查找/查找多选/主详字段 查找弹窗
     */
    beforeClose() {
      this.showSearchTable = false;
    },
    /**
     * 查找/查找多选/主详字段快速新建
     * @param {Object} item 字段信息
     */
    newSearchableField(item) {
      // 先查简档权限再新建
      CommonObjApi.getObjectPermission({ id: item.objId }).then((res) => {
        if (res.data.add && res.data.add) {
          // 父组件快速新建使用
          this.fieldId = item.fieldId;
          this.$emit("newSearchableField", item);
        } else {
          // 抱歉，您暂无该对象新建权限
          this.$message.warning(this.$i18n.t("message.nopermession"));
        }
      });
    },
    /**
     * 查找/查找多选/主详字段远程搜索方法
     * @param {String} query 查找条件
     * @param {Object} item 字段信息
     */
    remoteMethod(query, item) {
      // 查找单选字段搜索内容，用于快速新建带值
      if (item.type === "remote-select") {
        this.searchValueText = query;
      }

      if (query !== "") {
        setTimeout(() => {
          let params = {
            name: query,
            objId: item.prefix,
            fieldId: item.fieldId,
            lkdp: "",
            page: 1,
            pageSize: 20,
          };
          this.$emit("remoteMethod", params, item, this.formData);
        }, 200);
      }
    },
    /**
     * 远程搜索完成选择后清空搜索数组
     */
    clearFilterOptions() {
      this.filterOptions.splice(0);
    },
    /**
     * 图片类型字段 覆盖默认的上传行为，自定义上传的实现
     * @param {Object} params 文件信息
     */
    fileChange(params) {
      this.$emit("fileChange", params);
    },
    /**
     * 图片类型字段 删除文件触发的事件
     * @param {String} prop 字段信息
     * @param {Number} index 删除文件所在的索引位置
     */
    remove(prop, index) {
      this.$emit("remove", ...arguments);

      if (this.formData[prop] !== undefined || this.formData[prop] !== "") {
        let arrdele = this.formData[prop].split(",");
        arrdele.splice(index, 1);
        this.formData[prop] = arrdele.join(",");
      }
    },

    /**
     * 从项目名称打开搜索，且选择不同项目名称进行清空数据
     */
    porpValueChange() {
      //清空填写数据
      this.formData.milestone = "";
      this.formData.principal = "";
      this.formData.name = "";
      this.formData.start_date = "";
      this.formData.end_date = "";
      this.formData.milestone = "";
      this.formData.affected_milestones = "";
      this.formData.classification = "";
      this.formData.severity = "";
      this.formData.status = "";
      this.formData.recurrence_frequency = "";
      this.formData.continue_date = "";
      this.formData.billing = "";
      this.formData.problem_description = "";
      this.formData.notice_principal = "";
      this.formData.notification_item = "";
      this.formData.reminder_frequency = "";
    },
    /**
     * 设置了筛选条件的字段无需查找最近十条，不能新建。isenable_filter: "true"即设置了筛选条件
     * focusEvent:单选查找下拉框聚焦操作，获取最近查看的十条信息展示到option中
     * 当option中数据为空时，但是有新建权限，此时插入一条空的数据到option中，新建按钮设置定位,空数据value时$creat,label是'  '
     * @param {Object}  selectItem:当前查找项
     */
    focusEvent(selectItem) {
      // 设置了筛选条件的字段无需查找最近十条，不能新建。isenable_filter: "true"即设置了筛选条件
      if (selectItem.isenable_filter === "true") return;
      let itemVal = this.formData[selectItem.prop];
      // 判断新建权限
      let flag = this.selectCheckIsHiddenNewbulid(selectItem);
      // 如果是空，并且是多选，并且有新建权限,并且option为空
      if (
        itemVal.length === 0 &&
        flag &&
        selectItem.type === "remote-multi-select" &&
        this.optionList[selectItem.prop].length === 0
      ) {
        let list = [{ value: "$creat", label: "  " }];
        this.$parent.$set(this.optionList, selectItem.prop, list);
      }
      // 如果为空并且单选查找
      if (selectItem.type === "remote-select" && !itemVal) {
        // 单选查找
        // 用户对象、公海池对象特殊，没有最近十条信息，不需要查找
        let { objId } = selectItem;
        if (objId !== "user" && objId !== "marketsea") {
          this.getRemoteSelect(selectItem);
        }
      }
    },
    /**
     * getRemoteSelect:获取单选查找下拉框最近查看十条信息
     * @param {Object} selectItem:当前查找项
     */
    async getRemoteSelect(selectItem) {
      let { prefix, prop } = selectItem;
      // 参数固定
      let data = {
        obj: prefix || selectItem.lookupObj,
        viewId: "0",
        pageNum: 1,
        pageSize: 10,
        sortField: "",
        sortDir: "",
        searchKeyWord: "",
        conditionValues: "",
        ischangenum: "false",
        recordnums: 50,
        tagIds: "",
        isAllTag: "",
        campaignid: "false",
        isallsearch: "false",
        myself: "false",
        scrollId: "false",
        type: "",
        exactSearchFlag: "",
      };
      let res = await projectApi.getViewListData(data);
      if (res.data && res.data.list && res.data.list.length > 0) {
        //  将最近十条记录放入
        let list = [];
        res.data.list.forEach((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        // 保证list里面是唯一的
        const map = new Map();
        const result = list.filter(
          (v) => !map.has(v.value) && map.set(v.value, 1)
        );
        this.$parent.$set(this.optionList, prop, result);
      } else if (this.optionList[selectItem.prop].length === 0) {
        let list = [{ value: "$creat", label: "  " }];
        this.$parent.$set(this.optionList, selectItem.prop, list);
      }
    },

    /**
     * 计算事件差
     * @param {String} d1 开始时间
     * @param {String} d2 结束时间
     */
    timeFn(d1, d2) {
      //di作为一个变量传进来
      let dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
      let dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date
      return dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
    },
    /**
     * 满足条件时自动更改结束时间
     * @param {String} s 未知参数
     */
    autoEventDate(s) {
      let dateBegin = new Date(s.replace(/-/g, "/")); //将-转化为/，使用new Date
      let dateEnd = dateBegin.getTime() + this.timeDiff;
      //时间戳转换方法    d:时间戳数字
      function formatDate(d) {
        let date = new Date(d);
        let YY = date.getFullYear() + "-";
        let MM =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let hh =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        let mm =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":";
        let ss =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return YY + MM + DD + " " + hh + mm + ss;
      }
      this.formData.endtime = formatDate(dateEnd);
    },
    /**
     * 富文本字段内容变更时触发
     * @param {String} callback 字段绑定的回调函数名称
     * @param {String} content 字段内容
     * @param {Object} selectItem 字段信息
     */
    onContentChange(callback, content, selectItem) {
      this.formData[selectItem.prop] = content;
    },
    /**
     * 动态检索字段选中选项时触发
     * @param {Function} cb 回调函数
     * @param {String/Array} data 当前选中数据
     */
    selecter(cb, data) {
      cb(data);
    },
    //项目任务-工作时间-弹框宽度
    visiblechange() {
      let doms = document.getElementsByClassName("el-form-item__content");
      for (let i = 0; i < doms.length; i++) {
        if (doms[i].offsetWidth > 0) {
          this.worketwidth = doms[i].offsetWidth;
          break;
        }
      }
    },

    // 修改某个属性的键值
    changeProps(props, key, val) {
      for (let i = 0; i < this.formAttr.length; i++) {
        let item = this.formAttr[i];
        if (item.prop === props) {
          item[key] = val;
          break;
        }
      }
    },
    /**
     * 监听查找/查找多选/主详字段快速新建成功事件,快速新建成功后将数据添加到对应字段下
     */
    listenStorage() {
      window.addEventListener("storage", this.addRelevantObjectType);
    },
    addRelevantObjectType(event) {
      // 监听addRelevantObjectType变化
      if (event.key === "relevantObjectDataId") {
        let option = {
          value: localStorage.getItem("relevantObjectDataId"),
          label: localStorage.getItem("relevantObjectDataName"),
        };
        this.$emit(
          "addFieldOption",
          localStorage.getItem("relevantObjectApi"),
          option
        );
      }
    },
    tianyan() {
      this.$refs.tian.names(this.formData.name);
      this.$refs.tian.handleCloses();
    },
    //回填客户名
    setTianyanValue(val) {
      let formAttrs = [];
      this.formAttr.forEach((item) => {
        if (item.apiname === "name") {
          item.value = val;
          formAttrs.push(item);
        } else {
          formAttrs.push(item);
        }
      });

      this.formAttr = formAttrs;
      this.filterFormData();
    },
    /**
     * 修改天眼查匹配逻辑
     * author: wjl
     */
    setTianyanValuees(val = []) {
      this.formAttr.forEach((item) => {
        val.forEach((items) => {
          // 这里需要判断地址字段
          if (item.fieldType == "AD") {
            if (items.apiname.includes(item.apiname)) {
              if (Array.isArray(item.children)) {
                item.children.forEach((childrenItem) => {
                  if (childrenItem.name === items.apiname) {
                    childrenItem.value = items.value;
                    // 给formData赋值
                    if (childrenItem.value) {
                      this.tianyanchaFormData(childrenItem);
                    }
                  }
                });
              }
            }
          }
          if (item.apiname === items.apiname || item.name === items.apiname) {
            if (items.type === "D") {
              item.value =
                items.value && items.value !== "-"
                  ? this.$moment(items.value).format("YYYY-MM-DD")
                  : "";
            } else {
              item.value = items.value;
            }
            // 给formData赋值
            if (item.value) {
              // this.$set(this.formData, item.prop, item.value);
              this.tianyanchaFormData(item);
            }
          }
        });
      });
    },
    /**
     * 天眼查数据回显
     * @param {Object} v:字段信息
     */
    tianyanchaFormData(v = {}) {
      // 百分比、数字、币种字段若为空，设置成undefined，不让其显示成最小值
      if (
        (v.fieldType === "P" || v.fieldType === "N" || v.fieldType === "c") &&
        (v.value === "" || v.value === null)
      ) {
        this.$set(this.formData, v.prop, undefined);
      } else if (v.prop === "recordtype") {
        this.$set(this.formData, v.prop, v.id);
      } else if (v.type == "file" && v.data && v.value == null) {
        let val = "";
        v.data.forEach((res) => {
          val += res.id;
        });
        this.$set(this.formData, v.prop, val);
      } else if (v.fieldType == "AD") {
        //当类型为地址字段时，设置其子级数据
        v.children.forEach((res) => {
          this.$set(this.formData, res.prop, res.value);
        });
      } else if (v.fieldType == "B") {
        // 复选框类型
        if (v.value === "true") {
          this.$set(this.formData, v.prop, true);
        } else {
          this.$set(this.formData, v.prop, v.value);
        }
      } else {
        // 当v.value是数组时，去除v.value中的null和undefined,防止el-select组件 v-model绑定一个对象值
        if (Array.isArray(v.value)) {
          v.value = v.value.filter(
            (item) => item !== null && item !== undefined
          );
        }
        this.$set(this.formData, v.prop, v.value);
      }
    },
    /**
     * 获取当前用户信息
     */
    async getUserInfo() {
      let res = await CommonObjApi.getUserInfo();
      if (res.result) {
        this.userInfo = res.data;
      }
    },

    //后台验证 提示
    /**
     *
     * @param {*} data :错误提示信息
     * @param {*} flag :是否取消message提示
     */
    verify(data, flag) {
      // 防止验证规则的字段在布局上拿掉后无法提示的问题
      if (Array.isArray(data) && !flag) {
        data.forEach((item) => {
          if (item.errormessage) {
            // 后台验证 不符合规则 提示  wwwjjj
            this.$message.error(item.errormessage);
          }
        });
      }
      this.arryerror = [];
      for (let i = 0; i < this.formAttr.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (this.formAttr[i].apiname) {
            // data[j].errorField有大小写，需要都转化为小写
            if (this.formAttr[i].apiname == data[j].errorField.toLowerCase()) {
              this.formAttr[i].error = data[j].errormessage;
              // 后台验证 不符合规则 提示  wwwjjj
              // this.$message.error(data[j].errormessage);
              this.arryerror.push(this.formAttr[i].apiname);
            }
          }
        }
      }
      this.$forceUpdate();
    },
  },
  watch: {
    formDataNew: {
      handler: function (val, oldValue) {
        if (!this.arryerror) return;
        for (let i = 0; i < this.arryerror.length; i++) {
          let key = this.arryerror[i];
          if (val[key] !== oldValue[key]) {
            this.formAttr.forEach((item) => {
              if (item.apiname == key) {
                item.error = "";
              }
            });
          }
        }
      },
      deep: true,
    },
    "formData.start_date"() {
      if (this.formData.start_date && this.prefix == "p03") {
        this.workehoursstate = this.formData.start_date;
      }
    },
    "formData.principal"() {
      if (
        this.formData.principal &&
        this.prefix == "p03" &&
        this.optionList.principal
      ) {
        this.workehoursuser = [...this.optionList.principal].filter((x) =>
          [...this.formData.principal].some((y) => x.value == y)
        ); // 取差集
      }
    },
    "formData.end_date"() {
      if (this.formData.end_date && this.prefix == "p03") {
        this.workehoursend = this.formData.end_date;
      }
    },
  },
  computed: {
    /**
     * 每个输入框的判断，
     * 使用计算属性，增加公海池的所属公海池，有值就不能编辑的判断
     */
    inputCheckIsDisabled() {
      return (item) => {
        if (item.apiname === "marketsea") {
          // 如果是公海池对象
          if (item.value && this.isClientPoolEditStatus) {
            // 如果所属公海池对象有值，则禁用，不让其编辑
            return true;
          } else {
            // 否则则让其编辑
            return false;
          }
        } else if (
          this.isPartner &&
          (item.apiname == "accountid" || item.apiname == "pricebook2id")
        ) {
          // 如果是伙伴云 创建订单 则禁用客户和价格手册
          return true;
        } else {
          // 如果不是公海池则返回后台的判断行为
          return !item.edit;
        }
      };
    },
    /**
     * 下拉选项的判断，
     * 增加公海池的所属公海池的下拉选项，去掉【新建】的选项
     * 设置了筛选条件的字段不能新建。isenable_filter: "true"即设置了筛选条件
     */
    selectCheckIsHiddenNewbulid(item) {
      return (item) => {
        if (
          item.objId === "marketsea" ||
          item.objId === "user" ||
          item.isenable_filter === "true"
        ) {
          return false;
        } else {
          return true;
        }
      };
    },
    formDataNew() {
      return JSON.parse(JSON.stringify(this.formData));
    },
    /**
     * 返回当前表单中客户的名称
     */
    accountName() {
      if (this.optionList.accountid && this.optionList.accountid.length > 0) {
        return this.optionList.accountid[0].label;
      } else if (
        this.optionList.account_name &&
        this.optionList.account_name.length > 0
      ) {
        return this.optionList.account_name[0].label;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "@/style/variables.scss";
// 尽量不要在公共组件中更改组件库的样式
// ::v-deep .el-form-item {
//   margin-bottom: 0px;
//   // margin-bottom: -2px;
// }
// 字段依赖背景色
.bgColor {
  ::v-deep .el-input__inner {
    background-color: #e1f1e8;
  }
}
.localFileIcon {
  margin-right: 8px;
}
// 辅助字段展示样式
.auxiliaryField {
  float: left;
  display: block;
  position: absolute;
  margin-top: 16px;
  color: #666;
  font-size: 12px;
}
// 当有辅助字段的时候改变options的样式
.auxiliaryFieldBox {
  height: 38px;
  line-height: 26px;
}

::v-deep .el-form-item {
  margin-bottom: 0px;
  // margin-bottom: -2px;
}
::v-deep .big_map {
  margin: 10px 0;
  width: 80px;
  height: 20px;
  padding: 12px 10px;
  line-height: 1px;
  cursor: pointer;
  span {
    font-size: 12px;
  }
}

::v-deep .big-view-box {
  .el-dialog__header {
    padding: 0 !important;
    .el-dialog__headerbtn {
      display: none;
    }
  }
  .el-dialog__body {
    padding: 0 !important;
    .bm-view-big {
      width: 100%;
      height: 550px;
    }
    .btn_map {
      position: absolute;
      top: 16px;
      right: 90px;
      z-index: 9;
      button {
        height: 44px;
      }
    }
  }
}

.noDataLine {
  height: 62px;
  margin-bottom: 0;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  line-height: 40px;
  color: #ccc;
  float: left;
}

.stars span {
  font-size: 23px;
  margin-left: 4px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

// 查找下拉框不显示下拉图标
::v-deep .rightIconClass .el-input__suffix {
  right: 30px;
  .el-icon-arrow-up {
    display: none;
  }
}

::v-deep .no-suffix {
  .el-input__inner {
    cursor: text;
  }
}
// 会造成下拉多选错位
// ::v-deep .el-form-item__content {
//   line-height: 34px;
// }
// .remoteBtnMultiple{
//   height: calc(100% - 2px) !important;
//   top: 1px !important;
// }
// 使用calc设置高度，当出现验证规则错误提示，会导致样式错乱，所有height写死，当input为32px时，设置高度为30
.remoteBtn {
  position: absolute;
  height: 30px;
  // height: calc(100% - 4px);
  // line-height: 32px;
  right: 1px;
  top: 2px;
  z-index: 100;
  color: #c0c4cc;
  cursor: pointer;
  padding: 0 8px;
  border-radius: 0 4px 4px 0;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
}

// 帮助文本提示图标
.helpImg {
  width: 14px;
  margin-left: 5px;
  margin-top: -3px;
}

// facebook、linkedin、twitter图标
.linkedinImg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
  display: flex;
  ::v-deep font {
    display: flex;
    marquee {
      height: 100%;
    }
  }
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 18px;
  margin-bottom: 0;
  color: #080707;
  &:hover {
    background: #f5f7fa;
  }
}
// 新建按钮位置改变
.searchTipBoxFloat {
  margin-top: -35px;
  position: relative;
  &:hover {
    background-color: #f3f7ff;
  }
}
.el-input-number {
  width: 100%;

  ::v-deep .el-input__inner {
    text-align: left;
  }
}
// 控制了查找多选样式
::v-deep .el-select__tags {
  // margin-top: 5px;
  // top: 3%;
  // transform: translateY(0%);
  // height: 30px;
  max-height: 84px;
  overflow-y: auto;
}

::v-deep .el-input__inner {
  height: 30px;
}
.event-item {
  display: flex;
  // align-items: baseline;
  align-items: center;
  .event-item-l {
    width: 200px;
    // height: 40px;
  }
  .event-item-r {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    //color: #c3c7cf;
    font-size: inherit;
    height: 32px;
    // line-height: 40px;
    outline: 0;
    padding: 0 15px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

::v-deep .tianyancha {
  width: 85% !important;
  margin-right: 10px !important;
}

::v-deep .twitter {
  width: 95% !important;
  margin-right: 10px !important;
}

::v-deep .linkedin {
  width: 95% !important;
  margin-right: 10px !important;
}

::v-deep .facebook {
  width: 95% !important;
  margin-right: 10px !important;
}

::v-deep .dis {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
}

::v-deep .el-form-item__label {
  word-break: break-word;
  margin: 12px 0 4px 0;
  padding-left: 50px;
  font-size: 12px;
  color: #5f5e5e;
  line-height: 16px !important;
  font-size: 12px;
}
.el-textarea ::v-deep .el-textarea__inner {
  font-size: 14px !important;
  font-family: "微软雅黑",  Arial !important;
  color: #080707;
}
.base-form {
  padding-right: 5px;
}
::v-deep .pull-left {
  .el-select {
    // 解决新建、编辑、内联编辑下拉框宽度不齐的问题
    // 全局引入的样式污染
    padding-left: 0px !important;
  }
}
// 后台验证 不符合规则 提示样式 wwwjjj
.errormessage-text {
  color: #f56c6c;
}
.worked {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 9999;
  border: 1px solid #cccc;
}
.checkbox-word {
  color: #f11b21;
}

// 地址字段一列显示时样式
::v-deep .address-field .el-form-item__content {
  width: 95%;
}
.address-botton-box {
  right: 5%;
}
// 每个选项一行
.block-checkbox {
  display: block;
}
.inline-checkbox {
  min-width: 100px;
  margin: 4px;
}
</style>
